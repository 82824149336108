import styles from './PlayerButton.module.scss'

import { useFocusable } from '@dstv-web-leanback/norigin-spatial-navigation'

export function PlayerButton({
	focusKey,
	icon,
	iconReversed = false,
	logo,
	onSelect,
	label,
	round = true,
	extraMargin = false,
}) {
	const { ref, focused } = useFocusable({
		onEnterPress: onSelect,
		focusKey: focusKey,
	})

	return (
		<div
			ref={ref}
			className={`${styles.player_button} ${focused ? styles.focus : ''} ${label ? styles.has_label : ''} ${
				round ? styles.round : ''
			} ${extraMargin && !focused ? styles.extra_margin : ''}`}
		>
			{icon && <img src={icon} className={iconReversed ? styles.reversed : ''} />}
			{focused && (
				<>
					<span></span>
					{label && <div>{label}</div>}
					{logo && (
						<div className={styles.player_button_logo}>
							<img src={logo} />
						</div>
					)}
				</>
			)}
		</div>
	)
}

export default PlayerButton
