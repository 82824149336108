import { LOGIN, LOGIN_SUCCESS, LOGIN_ERROR, LOGOUT } from './auth/auth.slice'
import { GET_NEXT_EPISODE, GET_NEXT_EPISODE_SUCCESS, GET_NEXT_EPISODE_ERROR } from './autoplay/autoplay.slice'
import { GET_AVATARS, AVATARS_SUCCESS, AVATARS_ERROR, CLEAR_AVATARS } from './avatars/avatars.slice'
import { GET_BILLBOARD_DATA, BILLBOARD_SUCCESS, CLEAR_BILLBOARD } from './billboard/billboard.slice'
import { GET_BOOKMARKS, GET_BOOKMARKS_SUCCESS, GET_BOOKMARKS_ERROR } from './bookmarks/bookmarks.slice'
import {
	GET_CATCHUP,
	GET_CATCHUP_SUCCESS,
	GET_CATCHUP_WITH_FILTER,
	GET_CATCHUP_WITH_FILTER_SUCCESS,
	GET_CATCHUP_ERROR,
	CATCHUP_PAGING,
	CATCHUP_PAGING_SUCCESS,
} from './catchup/catchup.slice'
import { GET_CDN_TOKEN, GET_CDN_TOKEN_SUCCESS, GET_CDN_TOKEN_ERROR } from './cdn-auth/cdn-auth.slice'
import {
	CHANNELS_ERROR,
	GET_CHANNELS,
	GET_CHANNELS_SUCCESS,
	GET_DAY_EVENTS,
	GET_DAY_EVENTS_SUCCESS,
	GET_LIVE_TV_SECTIONS,
	GET_LIVE_TV_SECTIONS_SUCCESS,
} from './channels/channels.slice'
import { GET_REMOTE_CONFIGS, GET_REMOTE_CONFIGS_SUCCESS, GET_REMOTE_CONFIGS_ERROR } from './config/config.slice'
import {
	GET_ALL_DEVICES,
	REGISTER_DEVICE,
	REMOVE_DEVICE,
	DEVICES_SUCCESS,
	DEVICES_ERROR,
	REGISTER_DEVICE_SUCCESS,
	DELETE_DEVICE_SUCCESS,
} from './devices/devices.slice'
import { GET_ENTITLEMENT, GET_ENTITLEMENT_SUCCESS, GET_ENTITLEMENT_ERROR } from './entitlement/entitlement.slice'
import {
	GET_HOME,
	GET_HOME_SUCCESS,
	GET_HOME_ERROR,
	GET_HOME_INFO_ERROR,
	GET_HOME_CHANNEL_CARD_INFO,
	GET_HOME_CHANNEL_CARD_INFO_SUCCESS,
	GET_HOME_EVENT_INFO,
	GET_HOME_EVENT_INFO_SUCCESS,
	GET_HOME_CARD_INFO,
	GET_HOME_CARD_INFO_SUCCESS,
	GET_HOME_VOD_CARD_INFO,
	GET_HOME_VOD_CARD_INFO_SUCCESS,
} from './home/home.slice'
import {
	GET_PROXIMITY_STATUS,
	GET_PROXIMITY_STATUS_SUCCESS,
	GET_PROXIMITY_STATUS_ERROR,
} from './proximity/proximity.slice'
import { SET_KEYBOARD_VALUE, CLEAR_KEYBOARD } from './keyboard/keyboard.slice'
import { GET_MENU, GET_MENU_ERROR, GET_MENU_SUCCESS, MENU_TOGGLE } from './menu/menu.slice'
import { NAV_POP, NAV_PUSH, NAV_REPLACE } from './nav/nav.slice'
import { SET_ONBOARDING_STEP } from './onboarding/onboarding.slice'
import {
	GET_BOOKMARK,
	GET_BOOKMARK_SUCCESS,
	PLAY,
	SAVE_BOOKMARK,
	SAVE_BOOKMARK_SUCCESS,
	SAVE_BOOKMARK_ERROR,
	GET_BOOKMARK_ERROR,
	PLAYER_ERROR,
	RESET_PLAYER,
	GET_PLAYER_DETAILS,
	GET_PLAYER_DETAILS_SUCCESS,
} from './player/player.slice'
import {
	GET_PROFILES_LIST,
	GET_PROFILES_LIST_SUCCESS,
	GET_PROFILES_LIST_ERROR,
	SET_ACTIVE_PROFILE_ID,
	DELETE_PROFILE_FLAGS,
	EDIT_PROFILE,
	EDIT_PROFILE_SUCCESS,
	EDIT_PROFILE_ERROR,
	DELETE_PROFILE,
	DELETE_PROFILE_SUCCESS,
	DELETE_PROFILE_ERROR,
	CREATE_PROFILE,
	CREATE_PROFILE_SUCCESS,
	CREATE_PROFILE_ERROR,
	REMOVE_PROFILE_CRUD,
	REMOVE_PROFILE_ERROR,
} from './profile/profile.slice'
import { GET_ALL_SEARCH_RESULTS } from './search/search.slice'
import { SET_ACTIVE_SECTION } from './sections/sections.slice'
import { SESSION_REFRESH, SESSION_REFRESH_SUCCESS, SESSION_REFRESH_ERROR } from './session/session.slice'
import { AUTOPLAY_TOGGLE } from './settings/settings.slice'

import {
	CLEAR_SHOWPAGE,
	EDITORIALS,
	EDITORIALS_SUCCESS,
	EDITORIALS_ERROR,
	TOGGLE_WATCHLIST,
	TOGGLE_WATCHLIST_SUCCESS,
	TOGGLE_WATCHLIST_ERROR,
	GET_WATCH_LIST_INFO,
	GET_WATCH_LIST_INFO_SUCCESS,
	GET_WATCH_LIST_INFO_ERROR,
	HIDE_WATCHLIST_TOAST,
} from './showpage/showpage.slice'

import { GET_USER_INFO, GET_USER_INFO_ERROR, GET_USER_INFO_SUCCESS } from './user/user.slice'
import { WATCH_LIST, WATCH_LIST_CLEAR, WATCH_LIST_SUCCESS, WATCH_LIST_ERROR } from './watchlist/watchlist.slice'

const toType = (act) => !!act && (act?.type || act.toString())

export { LOGIN, LOGIN_SUCCESS, LOGIN_ERROR, LOGOUT }

export const ACTION_ERROR_CODE = {
	[`${toType(GET_USER_INFO)}`]: 'GD',
	[`${toType(GET_PROXIMITY_STATUS)}`]: 'GCS',
	[`${toType(GET_CATCHUP)}`]: 'CBPAC',
	[`${toType(GET_CHANNELS)}`]: 'CHA',
	[`${toType(DELETE_PROFILE)}`]: 'PRO',
	[`${toType(EDIT_PROFILE)}`]: 'PRO',
	[`${toType(CREATE_PROFILE)}`]: 'PRO',
	[`${toType(EDITORIALS)}`]: 'GC',
	[`${toType(GET_HOME)}`]: 'GEFH',
	[`${toType(GET_AVATARS)}`]: 'AVA',
	[`${toType(GET_PROFILES_LIST)}`]: 'PRO',
	[`${toType(LOGIN)}`]: 'REG',
	[`${toType(GET_MENU)}`]: 'NAV',
	[`${toType(GET_WATCH_LIST_INFO)}`]: 'ML',
	[`${toType(REGISTER_DEVICE)}`]: 'VD',
	[`${toType(REMOVE_DEVICE)}`]: 'DRD',
	[`${toType(GET_ALL_SEARCH_RESULTS)}`]: 'SEA',
	[`${toType(SET_ACTIVE_SECTION)}`]: 'SEC',
	[`${toType(GET_LIVE_TV_SECTIONS)}`]: 'SEC',
	[`${toType(WATCH_LIST)}`]: 'WAT',
	[`${toType(TOGGLE_WATCHLIST)}`]: 'WAT',
	[`${toType(GET_HOME_CHANNEL_CARD_INFO)}`]: 'GEC',
	[`${toType(GET_HOME_VOD_CARD_INFO)}`]: 'GC',
	[`${toType(GET_HOME_CARD_INFO)}`]: 'GC',
	[`info/GET_VIDEO`]: 'GC',
	[`info/GET_PROGRAM`]: 'GC',
	[`${toType(GET_BOOKMARK)}`]: 'GB',
	[`${toType(GET_BOOKMARKS)}`]: 'BOO',
	// [ `${toType(GET_HOME_CHANNEL_)}` ]: 'GAB',
	[`${toType(SAVE_BOOKMARK)}`]: 'SB',
	[`${toType(GET_DAY_EVENTS)}`]: 'GCEBT',
	[`${toType(GET_HOME_EVENT_INFO)}`]: 'GCEBT',
	// [ `${toType(GET_FOOTER)}` ]: 'FOO',
	[`${toType(GET_BILLBOARD_DATA)}`]: 'BIL',
	// [ `${toType(GET_CONCURRENCY_STATUS)}` ]: 'GCS',
	[`${toType(GET_REMOTE_CONFIGS)}`]: 'REM',
	[`${toType(GET_ALL_DEVICES)}`]: 'GD',
	[`${toType(SESSION_REFRESH)}`]: 'REF',
	[`${toType(GET_ENTITLEMENT)}`]: 'VC',
	[`channels/GET_RECENTLY_WATCHED_CHANNELS`]: 'RWC',
	[`${toType(NAV_PUSH)}`]: 'NAV',
	// [`${toType(GET_EDITORIAL_ITEM)}`]: 'GEFI',
	// [ `${toType(GET_TRY_THIS)}` ]: 'GEFI',
	// [`${toType(GET_CHANNEL_BY_ID)}`]: 'GEC',
	[`${toType(GET_CATCHUP_WITH_FILTER)}`]: 'GECG',
	// [ `${toType(GET_CHANNEL_SCHEDULES)}` ]: 'GESBT',
}

// export const AllReducerAction = {
// 	appList: { GET_APPLIST, GET_APPLIST_SUCCESS, GET_APPLIST_ERROR },
// 	auth: { LOGIN, LOGIN_SUCCESS, LOGIN_ERROR, LOGOUT },
// 	autoplay: { GET_NEXT_EPISODE, GET_NEXT_EPISODE_SUCCESS, GET_NEXT_EPISODE_ERROR },
// 	avatars: { GET_AVATARS, AVATARS_SUCCESS, AVATARS_ERROR, CLEAR_AVATARS },
// 	billboard: {
// 		GET_BILLBOARD_DATA,
// 		BILLBOARD_SUCCESS,
// 		CLEAR_BILLBOARD,
// 		TOGGLE_WATCHLIST,
// 		TOGGLE_WATCHLIST_SUCCESS,
// 		TOGGLE_WATCHLIST_ERROR,
// 		GET_WATCH_LIST_INFO,
// 		GET_WATCH_LIST_INFO_SUCCESS,
// 		GET_WATCH_LIST_INFO_ERROR,
// 		HIDE_WATCHLIST_TOAST,
// 	},
// 	bookmarks: {
// 		GET_BOOKMARKS,
// 		GET_BOOKMARKS_SUCCESS,
// 		GET_BOOKMARKS_ERROR,
// 	},
// 	catchup: {
// 		GET_CATCHUP,
// 		GET_CATCHUP_SUCCESS,
// 		GET_CATCHUP_WITH_FILTER,
// 		GET_CATCHUP_WITH_FILTER_SUCCESS,
// 		GET_CATCHUP_ERROR,
// 		CATCHUP_PAGING,
// 		CATCHUP_PAGING_SUCCESS,
// 	},
// 	cdnAuth: { GET_CDN_TOKEN, GET_CDN_TOKEN_SUCCESS, GET_CDN_TOKEN_ERROR },
// 	channels: {
// 		GET_CHANNELS,
// 		CHANNELS_ERROR,
// 		GET_CHANNELS_SUCCESS,
// 		GET_DAY_EVENTS,
// 		GET_DAY_EVENTS_SUCCESS,
// 		GET_LIVE_TV_SECTIONS,
// 		GET_LIVE_TV_SECTIONS_SUCCESS,
// 	},
// 	config: {
// 		GET_REMOTE_CONFIGS,
// 		GET_REMOTE_CONFIGS_SUCCESS,
// 		GET_REMOTE_CONFIGS_ERROR,
// 		REGISTER_DEVICE,
// 		REMOVE_DEVICE,
// 		DEVICES_SUCCESS,
// 		DEVICES_ERROR,
// 		REGISTER_DEVICE_SUCCESS,
// 		DELETE_DEVICE_SUCCESS,
// 	},
// 	entitlement: { GET_ENTITLEMENT, GET_ENTITLEMENT_SUCCESS, GET_ENTITLEMENT_ERROR },
// 	home: {
// 		GET_HOME,
// 		GET_HOME_SUCCESS,
// 		GET_HOME_ERROR,
// 		GET_HOME_INFO_ERROR,
// 		GET_HOME_CHANNEL_CARD_INFO,
// 		GET_HOME_CHANNEL_CARD_INFO_SUCCESS,
// 		GET_HOME_EVENT_INFO,
// 		GET_HOME_EVENT_INFO_SUCCESS,
// 		GET_HOME_VOD_CARD_INFO,
// 		GET_HOME_VOD_CARD_INFO_SUCCESS,
// 	},
// 	keyboard: { SET_KEYBOARD_VALUE, CLEAR_KEYBOARD },
// 	menu: { GET_MENU, GET_MENU_ERROR, GET_MENU_SUCCESS, MENU_TOGGLE },
// 	nav: { NAV_POP, NAV_PUSH, NAV_REPLACE },
// 	onboarding: { SET_ONBOARDING_STEP },
// 	player: {
// 		GET_BOOKMARK,
// 		GET_BOOKMARK_SUCCESS,
// 		PLAY,
// 		SAVE_BOOKMARK,
// 		SAVE_BOOKMARK_SUCCESS,
// 		SAVE_BOOKMARK_ERROR,
// 		GET_BOOKMARK_ERROR,
// 		PLAYER_ERROR,
// 		RESET_PLAYER,
// 		GET_PLAYER_DETAILS,
// 		GET_PLAYER_DETAILS_SUCCESS,
// 	},
// 	profile: {
// 		GET_PROFILES_LIST,
// 		GET_PROFILES_LIST_SUCCESS,
// 		GET_PROFILES_LIST_ERROR,
// 		SET_ACTIVE_PROFILE_ID,
// 		DELETE_PROFILE_FLAGS,
// 		EDIT_PROFILE,
// 		EDIT_PROFILE_SUCCESS,
// 		EDIT_PROFILE_ERROR,
// 		DELETE_PROFILE,
// 		DELETE_PROFILE_SUCCESS,
// 		DELETE_PROFILE_ERROR,
// 		CREATE_PROFILE,
// 		CREATE_PROFILE_SUCCESS,
// 		CREATE_PROFILE_ERROR,
// 		REMOVE_PROFILE_CRUD,
// 		REMOVE_PROFILE_ERROR,
// 	},
// 	search: { GET_ALL_SEARCH_RESULTS },
// 	section: { SET_ACTIVE_SECTION },
// 	session: { SESSION_REFRESH, SESSION_REFRESH_SUCCESS, SESSION_REFRESH_ERROR },
// 	settings: { AUTOPLAY_TOGGLE },
// 	showpage: { BILLBOARD_TOGGLE, CLEAR_SHOWPAGE, EDITORIALS, EDITORIALS_SUCCESS, EDITORIALS_ERROR },
// 	user: { GET_USER_INFO, GET_USER_INFO_ERROR, GET_USER_INFO_SUCCESS },
// 	watchlist: { WATCH_LIST, WATCH_LIST_CLEAR, WATCH_LIST_SUCCESS, WATCH_LIST_ERROR },
// }

// export const allActions = entries(AllReducerAction)
// .reduce(
// 	(acc, [_reducerKey, actionMap]) =>
// 		Object.assign(acc, fromEntries(entries(actionMap).map(([k, act]) => [k, toType(act)]))),
// 	{}
// )
// (function(){console.log({
// 	// allActions,
// 	 ACTION_ERROR_CODE,
// 	//  AllReducerAction,
// 	})})()
