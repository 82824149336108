import { useRef, useState } from 'react'
import { FocusContext, setFocus, useFocusable } from '@dstv-web-leanback/norigin-spatial-navigation'
import { useEffect } from 'react'
import EpisodeListItem from '../../container/EpisodeListItem/EpisodeListItem'

import styles from './EpisodeList.module.scss'
import { SHOWPAGE_FEATURE_KEY } from '@dstv-web-leanback/dstv-frontend-services'
import { useSelector } from 'react-redux'

export function EpisodeList({ data, handleBack }) {
	const { focusKey, ref, focusSelf } = useFocusable({
		onBackPress: handleBack,
	})

	const { title, seasons } = data
	const season = seasons?.[0]
	const showpageState = useSelector((state) => state[SHOWPAGE_FEATURE_KEY])

	useEffect(() => {
		if (showpageState.watchButton?.resume_video_id) {
			setFocus('EpisodeListItem-' + showpageState.watchButton?.resume_video_id)
		} else {
			focusSelf()
		}
	}, [focusSelf, setFocus, data?.seasons, showpageState.watchButton?.resume_video_id])

	const listWrapper = useRef()
	const [listScroll, setListScroll] = useState(0)

	const changeListFocus = (focusedRef) => {
		if (focusedRef.current) {
			setListScroll(focusedRef.current.offsetTop - Number.parseInt(getComputedStyle(ref.current).paddingTop))
		}
	}

	return (
		<FocusContext.Provider value={focusKey}>
			<div className={styles.episodes} ref={listWrapper}>
				<div className={styles.heading_wrapper}>
					<h2>
						{title}
						{!!season?.seasonNumber && <span>Season {season?.seasonNumber}</span>}
					</h2>
				</div>
				<div className={styles.episode_list_wrapper}>
					<div ref={ref} className={styles.episode_list} style={{ transform: `translate3d(0,-${listScroll}px,0)` }}>
						<div className={styles.episode_wrapper}>
							{season?.videos.map((video, i) => (
								<EpisodeListItem
									focusKey={'EpisodeListItem-' + video?.genRef}
									data={video}
									itemFocus={changeListFocus}
									key={'EpisodeListItem' + i}
								/>
							))}
						</div>
					</div>
				</div>
			</div>
		</FocusContext.Provider>
	)
}
export default EpisodeList
