import { useEffect, useState } from 'react'

import styles from './PlayerThumbnail.module.scss'

export function PlayerThumbnail({
	thumbnailCount,
	thumbnailImage,
	currentTime,
	duration,
	position,
	time,
	columnCount,
	isLive,
}) {
	let index = Math.floor((currentTime / duration) * thumbnailCount)
	let column = index % columnCount
	let row = Math.floor(index / columnCount)

	return (
		<div className={styles.player_thumbnail_wrapper}>
			<div
				className={isLive ? styles.player_timestamp : styles.player_thumbnail}
				style={
					isLive
						? {
								marginLeft: `${position}%`,
						  }
						: {
								backgroundImage: `url(${thumbnailImage})`,
								backgroundPosition: `-${column * 16.667}vw -${row * 9.375}vw`,
								marginLeft: `${position}%`,
								backgroundSize: `${columnCount * 16.667}vw`,
						  }
				}
			>
				<span className={styles.time_pill}>{time}</span>
			</div>
		</div>
	)
}
export default PlayerThumbnail
