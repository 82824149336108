import { createSlice } from '@reduxjs/toolkit'
import { filter, mergeMap } from 'rxjs/operators'
import { of } from 'rxjs'

import { getHeaders, handleUnauthorized } from '../../utils/authHelper'
import { ENV } from '../../utils/environment'
import { errorResponse, HTTP, successResponse } from '../../utils/httpHelper'
import { secureLocalStorage } from '../../utils/secureStorage'

export const USER_FEATURE_KEY = 'user'

export const userSlice = createSlice({
	name: USER_FEATURE_KEY,
	initialState: {},
	reducers: {
		GET_USER_INFO: (state, action) => {
			delete state.error
			delete state.serverError
			state.loading = true
		},
		GET_USER_INFO_SUCCESS: (state, action) => {
			secureLocalStorage.setItem('userInfo', action.payload.data)
			secureLocalStorage.setItem('userPackage', action.payload.data.package)
			state.userPackage = action.payload.data.package
			state.playbackcapabilities = action.payload.data.playbackcapabilities
			state.loading = false
		},
		GET_USER_INFO_ERROR: (state, action) => {
			state.error = action.payload.error
			state.serverError = action.payload.serverError
			state.loading = false
		},
	},
})
/*
 * Export reducer for store configuration.
 */
export const userReducer = userSlice.reducer

export const { GET_USER_INFO, GET_USER_INFO_SUCCESS, GET_USER_INFO_ERROR } = userSlice.actions

export const getUserState = (rootState) => rootState[USER_FEATURE_KEY]

export const userEpic = (action$) => action$.pipe(filter(GET_USER_INFO.match), mergeMap(userService(action$)))

export const userService = (action$) => (action) =>
	HTTP.GET(ENV.GET_USER_INFO, getHeaders(), userSuccess, userError(action), true)

const userSuccess = (response) => {
	let userInfo = []
	// we will need to sync this up. We are using different keys for same data values
	if (response) {
		userInfo = {
			package: response['urn:cv:dstvnow:user:packages'][0],
			userPackage: response['urn:cv:dstvnow:user:packages'][0],
			country: response['urn:cv:dstvnow:user:country'][0],
			playbackcapabilities: response['urn:cv:dstvnow:user:playbackcapabilities'],
		}
	}

	return {
		type: GET_USER_INFO_SUCCESS.type,
		payload: successResponse(userInfo),
	}
}

const userError = (action) => (response) => {
	return of(
		handleUnauthorized(
			response,
			{
				type: GET_USER_INFO_ERROR.type,
				payload: errorResponse(response, action),
			},
			action
		)
	)
}
