import { createSlice } from '@reduxjs/toolkit'
import { ofType } from 'redux-observable'
import { getHeaders } from '../../utils/authHelper'
import { ENV } from '../../utils/environment'
import { HTTP, successResponse } from '../../utils/httpHelper'
import { mergeMap } from 'rxjs/operators'
import { of } from 'rxjs'

export const BOOKMARKS_FEATURE_KEY = 'bookmarks'

const resetState = (state, action) => {
	delete state.error
	state.bookmarks = null
	state.date = new Date().getTime()
}

export const bookmarksSlice = createSlice({
	name: BOOKMARKS_FEATURE_KEY,
	initialState: {
		bookmarks: null,
		loading: true,
	},
	reducers: {
		GET_BOOKMARKS: (state, action) => resetState(state, action),
		GET_BOOKMARKS_SUCCESS: (state, action) => {
			state.bookmarks = action.payload.data
			state.date = new Date().getTime()
			state.loading = false
		},
		GET_BOOKMARKS_ERROR: (state, action) => {
			state.bookmarks = null
			state.date = new Date().getTime()
			state.error = true
			state.loading = false
		},
	},
})

/*
 * Export reducer for store configuration.
 */
export const bookmarksReducer = bookmarksSlice.reducer

/*
 * Export actions
 */
export const { GET_BOOKMARKS, GET_BOOKMARKS_SUCCESS, GET_BOOKMARKS_ERROR } = bookmarksSlice.actions

/*
 * Set up the redux-observable epic
 */
export const bookmarksEpic = (action$) => action$.pipe(ofType(GET_BOOKMARKS.type), mergeMap(bookmarksService(action$)))

/*
 * Do API calls
 */
export const bookmarksService = (action$) => (action) => {
	switch (action.type) {
		case GET_BOOKMARKS.type: {
			return HTTP.GET_WITH_CANCEL(
				ENV.BASE_URL + ENV.GET_BOOKMARKS.replace('{GENREF}', action.payload.genRefs),
				getHeaders(),
				getBookmarksSuccess,
				getBookmarksError,
				false,
				action$.pipe(ofType(GET_BOOKMARKS))
			)
		}
	}
}

const getBookmarksSuccess = (response) => {
	return {
		type: GET_BOOKMARKS_SUCCESS.type,
		payload: { ...successResponse(response) },
	}
}

const getBookmarksError = (response) =>
	of({
		type: GET_BOOKMARKS_ERROR.type,
	})
