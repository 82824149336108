import styles from './SectionNavItem.module.scss'

import { setFocus, useFocusable } from '@dstv-web-leanback/norigin-spatial-navigation'

export function SectionNavItem({ active, index, title, itemFocus, id }) {
	const { ref, focused, onFocus } = useFocusable({
		onFocus: () => {
			itemFocus(index, ref)
		},
		focusKey: `SUBMENU_${index}`,
	})

	return (
		<div
			ref={ref}
			className={`${styles.section_nav_item} ${active ? styles.active : ''} ${focused ? styles.focus : ''}`}
			onFocus={onFocus}
		>
			{title}
		</div>
	)
}
export default SectionNavItem
