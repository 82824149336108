import { useEffect, useState } from 'react'
import { useFocusable, FocusContext } from '@dstv-web-leanback/norigin-spatial-navigation'

import {
	KeyboardKey,
	KeyboardActionButton,
	KeyboardNotFocusedButton,
} from '@dstv-web-leanback/dstv-frontend-components'
import { CLEAR_KEYBOARD, SET_KEYBOARD_VALUE } from '@dstv-web-leanback/dstv-frontend-services'
import { keyboardButtons } from './helpers'
import styles from './Keyboard.module.scss'

import SpaceSprite from '../../../assets/images/smarttv/space.svg'
import ClearSprite from '../../../assets/images/smarttv/clear.svg'
import { useSelector, useDispatch } from 'react-redux'

export function Keyboard({ initialValue, actions, okAction, backAction, maxLength = null }) {
	const { ref, focusKey, focusSelf } = useFocusable({
		onBackPress: backAction,
	})

	const dispatch = useDispatch()
	const keyboardState = useSelector((state) => state.keyboard)

	const [uppercase, setUppercase] = useState(false)
	const [symbols, setSymbols] = useState(false)
	const [keyboardKeys, setKeyboardKeys] = useState(keyboardButtons.keypad)

	useEffect(() => {
		focusSelf()

		if (initialValue) {
			dispatch(SET_KEYBOARD_VALUE({ value: initialValue }))
		}
	}, [])

	useEffect(() => {
		setKeyboardKeys(
			symbols ? keyboardButtons.symbols : uppercase ? keyboardButtons.keypadUppercase : keyboardButtons.keypad
		)
	}, [uppercase, symbols])

	const selectKey = (key) => {
		let keyboardValue = keyboardState.value
		if ((maxLength && keyboardValue.length <= maxLength) || !maxLength) {
			keyboardValue += key
			dispatch(SET_KEYBOARD_VALUE({ value: keyboardValue }))
		}
	}

	const selectAction = (action) => {
		let keyboardValue = keyboardState.value
		switch (action) {
			case 'space': {
				keyboardValue += ' '
				dispatch(SET_KEYBOARD_VALUE({ value: keyboardValue }))
				break
			}
			case 'clear': {
				keyboardValue = keyboardValue.slice(0, -1)
				dispatch(SET_KEYBOARD_VALUE({ value: keyboardValue }))
				break
			}
		}
	}

	const toggleKeyboard = (type) => {
		switch (type) {
			case 'symbols': {
				setSymbols(!symbols)
				setUppercase(false)
				break
			}
			case 'case': {
				setUppercase(!uppercase)
				setSymbols(false)
				break
			}
			default: {
				setUppercase(false)
				setSymbols(false)
				setKeyboardKeys(keyboardButtons.keypad)
			}
		}
	}

	return (
		<FocusContext.Provider value={focusKey}>
			<div ref={ref} className={styles.keyboard_wrapper}>
				<div className={styles.keyboard_keys}>
					{keyboardKeys.map((label, index) => {
						return <KeyboardKey key={label} label={label} selectKey={selectKey} index={index} />
					})}
				</div>
				<div className={styles.action_buttons}>
					{actions.map((action) => {
						switch (action) {
							case 'space':
								return (
									<KeyboardActionButton
										action={keyboardButtons.actionButtons[action].label}
										key={keyboardButtons.actionButtons[action].label}
										selectAction={selectAction}
										spriteImage={SpaceSprite}
										focusKey={action}
									/>
								)
							case 'clear':
								return (
									<KeyboardActionButton
										action={keyboardButtons.actionButtons[action].label}
										key={keyboardButtons.actionButtons[action].label}
										selectAction={selectAction}
										spriteImage={ClearSprite}
										focusKey={action}
									/>
								)
							case 'symbols':
								return (
									<KeyboardActionButton
										label={
											symbols
												? keyboardButtons.actionButtons[action].labelActive
												: keyboardButtons.actionButtons[action].label
										}
										key={keyboardButtons.actionButtons[action].label}
										selectAction={() => {
											toggleKeyboard('symbols')
										}}
										focusKey={action}
									/>
								)
							case 'case':
								return (
									<KeyboardActionButton
										label={
											uppercase
												? keyboardButtons.actionButtons[action].labelActive
												: keyboardButtons.actionButtons[action].label
										}
										key={keyboardButtons.actionButtons[action].label}
										selectAction={() => {
											toggleKeyboard('case')
										}}
										focusKey={action}
									/>
								)
							case 'ok_not_focused':
								return (
									<KeyboardNotFocusedButton
										cssClass="ok_button"
										label={keyboardButtons.actionButtons[action].label}
										key={keyboardButtons.actionButtons[action].label}
									/>
								)
							default:
								return (
									<KeyboardActionButton
										cssClass="ok_button"
										label={keyboardButtons.actionButtons[action].label}
										key={keyboardButtons.actionButtons[action].label}
										selectAction={() => {
											okAction()
										}}
										focusKey={action}
									/>
								)
						}
					})}
				</div>
			</div>
		</FocusContext.Provider>
	)
}
export default Keyboard
