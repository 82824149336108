import { useFocusable } from '@dstv-web-leanback/norigin-spatial-navigation'
import styles from './ModalItem.module.scss'

export const ModalItem = ({
	focusedicon,
	focusable,
	defaulticon,
	text,
	index,
	onSelect,
	handleRelease,
	iconStyles,
}) => {
	const { ref, focused } = useFocusable({
		focusKey: `MODAL_ITEM_${index}`,
		onEnterPress: () => {
			if (focusable) {
				onSelect?.()
			}
		},
		onEnterRelease: handleRelease,
	})
	return (
		<div ref={ref} className={[styles.cwmodal_item, focused ? styles.focus : ''].join(' ')}>
			<div className={styles.icon}>
				{' '}
				<img src={focused ? focusedicon : defaulticon} alt={'icon'} className={iconStyles} />
			</div>
			<div>{text}</div>
		</div>
	)
}
export default ModalItem
