import { useState, useRef, useEffect } from 'react'
import { useFocusable, FocusContext } from '@dstv-web-leanback/norigin-spatial-navigation'
import { CardPortrait } from '@dstv-web-leanback/dstv-frontend-components'
import styles from './Grid.module.scss'

export function Grid({ cards, itemFocus, paginateCatchupGrid }) {
	const wrapperRef = useRef(null)

	const [gridScroll, setGridScroll] = useState(0)
	const [scrollAdjust, setScrollAdjust] = useState(0)

	const changeGridFocus = (focusedRef, item, index) => {
		if (focusedRef.current && ref.current && wrapperRef.current) {
			itemFocus(item)

			const wrapperStyles = getComputedStyle(wrapperRef.current)
			const cardStyles = getComputedStyle(focusedRef.current)
			const offsetTop = focusedRef.current.offsetTop
			const marginTop = Math.round(Number.parseFloat(cardStyles.marginTop))
			const paddingTop = Number.parseInt(wrapperStyles.paddingTop)

			// determine if parent's padding is included in offset top
			let newScrollAdjust = scrollAdjust
			if (gridScroll === 0 && offsetTop - paddingTop === marginTop) {
				newScrollAdjust = paddingTop
				setScrollAdjust(newScrollAdjust)
			}

			setGridScroll(offsetTop - newScrollAdjust + marginTop)

			// Get next page
			if (cards.length > 7 && index > cards.length - 7) {
				paginateCatchupGrid && paginateCatchupGrid()
			}
		}
	}

	const { focusKey, ref } = useFocusable({
		focusKey: 'GRID',
		onBlur: () => setGridScroll(0),
		saveLastFocusedChild: false,
	})

	return (
		<FocusContext.Provider value={focusKey}>
			<div className={styles.grid_wrapper} ref={wrapperRef}>
				<div className={styles.grid} ref={ref} style={{ transform: `translate3d(0, -${gridScroll}px, 0)` }}>
					{cards.map((card, index) => {
						return <CardPortrait index={index} item={card} itemFocus={changeGridFocus} key={index} />
					})}
				</div>
			</div>
		</FocusContext.Provider>
	)
}
export default Grid
