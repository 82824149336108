import { createSlice } from '@reduxjs/toolkit'
import { mergeMap } from 'rxjs/operators'
import { of } from 'rxjs'
import { ofType } from 'redux-observable'

import { ENV } from '../../utils/environment'
import { errorResponse, HTTP } from '../../utils/httpHelper'

import { getHeaders, handleUnauthorized } from '../../utils/authHelper'
import { getCardPosterImageURL } from '../../utils/transformHelper'

export const SEARCH_FEATURE_KEY = 'search'

/*
 * Create our slice
 */
export const searchSlice = createSlice({
	name: SEARCH_FEATURE_KEY,
	initialState: {},
	reducers: {
		GET_ALL_SEARCH_RESULTS: (state, action) => {
			delete state.error
			delete state.serverError
			state.isLoading = true
		},
		SEARCH_SUCCESS: (state, action) => {
			state.isLoading = false
			state.items = action.payload.list
		},
		UPDATE_SEARCH_KEY: (state, action) => {
			state.isLoading = false
			delete state.error
			delete state.serverError
			state.items = null
			state.searchKey = action.payload.searchKey
		},
		SEARCH_ERROR: (state, action) => {
			state.items = null
			state.isLoading = false
			state.error = action.payload?.error
			state.serverError = action.payload?.serverError
		},
		CLEAR_SEARCH: (state) => {
			state.items = null
			state.searchKey = ''
		},
	},
})

/*
 * Export reducer for store configuration.
 */
export const searchReducer = searchSlice.reducer

/*
 * Export actions
 */

export const { SEARCH_SUCCESS, GET_ALL_SEARCH_RESULTS, UPDATE_SEARCH_KEY, SEARCH_ERROR, CLEAR_SEARCH } =
	searchSlice.actions

/*
 * Set up the redux-observable epic
 */

export const searchEpic = (action$) =>
	action$.pipe(ofType(GET_ALL_SEARCH_RESULTS.type), mergeMap(searchService(action$)))

/*
 * Do API calls
 */

const searchService = (action$) => (action) => {
	const { payload } = action
	switch (action.type) {
		case GET_ALL_SEARCH_RESULTS.type: {
			return HTTP.GET(
				ENV.GET_SEARCH.replace('{SEARCH_TERM}', payload.searchKey),
				getHeaders(),
				showAll(payload),
				showError(payload)
			)
		}
	}
}

/*
 * Dispatch actions based on API responses
 */
export const showAll = (action) => (response) => {
	let list = []
	const editorialItems = response?.items?.[0]?.editorialItems

	if (editorialItems) {
		list.push(
			...editorialItems.map((item) => {
				item.poster_image = getCardPosterImageURL(item)
				return item
			})
		)
	}

	return { type: SEARCH_SUCCESS.type, payload: { list: list, searchKey: action.searchKey } }
}

export const showError = (action) => (response) => {
	return of(
		handleUnauthorized(
			response,
			{
				type: SEARCH_ERROR.type,
				payload: errorResponse(response, action),
			},
			action
		)
	)
}
