import { getVideoId, getVideoIdByGenRef } from '../../utils/transformHelper'
import { dateUtils } from '@dstv-web-leanback/dstv-frontend-utils'
import moment from 'moment'

export const getBillboardPosterURL = (response, type) => {
	switch (type) {
		case 'channels': {
			let data =
				response &&
				response.event_images &&
				response.event_images.filter((item) => {
					return item.rel[0] === 'thumbnailUri' && item.rel[1] === 'XLARGE'
				})
			return data?.[0]?.href
		}
		case 'event': {
			let data =
				response &&
				(response.event_images || response.images) &&
				(response.event_images || response.images).filter((item) => {
					return item.rel[0] === 'poster' || item.rel[0] === 'hero'
				})
			return data?.[0]?.href
		}
		case 'card': {
			const poster = response?.card_images ?? response?.images
			let data = poster?.filter((item) => {
				return (
					(item.rel[0] === 'hero' && item.rel[1] === 'LARGE') ||
					(item.rel[0] === 'poster-landscape' && item.rel[1] === 'hero') ||
					(item.rel[0] === 'hero' && item.rel.length === 1)
				)
			})
			return data?.[0]?.href
		}
	}
}

export const getBillboardChannelLogoURL = (response, type) => {
	switch (type) {
		case 'event': {
			let data =
				response &&
				response.images &&
				response.images.filter((item) => {
					return item.rel[0] === 'channel_logo'
				})
			return data?.[0]?.href
		}
		case 'channels':
			return response?.images[0]?.href
	}
}

export const getBillboardShowLogoURL = (response, type) => {
	switch (type) {
		case 'event': {
			let data =
				response &&
				response.images &&
				response.images.filter((item) => {
					return item.rel[0] === 'show-logo' && item.rel[1] === 'XLARGE'
				})
			return data?.[0]?.href
		}
		case 'card': {
			const logo = response.card_images || response.images
			let data =
				response &&
				logo?.filter((item) => {
					return (
						(item.rel[0] === 'show-logo' && item.rel[1] === 'XLARGE') ||
						(item.rel[1] === 'show-logo' && item.rel[0] === 'logo')
					)
				})
			return data?.[0]?.href
		}
	}
}

const formatTime = (time) => (time ? dateUtils.formatTimeToHoursAndMinutes(time) : '')

const getLiveTVBillboardData = (item) => {
	let data = {
		title: item?.title,
		channelLogo: item?.channelLogo,
		channelNumber: item?.channelNumber,
		poster: item?.image,
		synopsis: item?.description,
		metadata:
			formatTime(item?.start) +
			' - ' +
			formatTime(item?.end) +
			'   ' +
			(item?.seasonNumber !== '0' ? `SEASON ${item.seasonNumber} ` : '') +
			(item?.secondaryGenre ? item.secondaryGenre.toUpperCase().replace('|', ', ') : ''),
		rating: item?.rating,
	}
	return data
}

const channelLive = (item) => {
	const startTime = item?.event_start_date_time || item?.start_date_time
	const endTime = item?.event_end_date_time || item?.end_date_time
	const currentTime = moment()
	// checking if the channel is live based on the card time
	return startTime && endTime && currentTime.isBefore(endTime) ? currentTime.isAfter(startTime) : undefined
}

export const getTimeLeft = (info) => {
	const currentTime = moment()
	const endMoment = moment(info?.endTime)
	const timeBetweenDates = dateUtils.getTimeBetweenDates(endMoment, currentTime) + 1

	return timeBetweenDates > 0 ? ` • ${dateUtils.convertMinsToHrsMins(timeBetweenDates)}` : ''
}

const getChannelsMetadata = (item, type) => {
	let relativeDateString = ''
	let metadata

	const startTime = formatTime(item?.event_start_date_time || item?.start_date_time)
	const endTime = formatTime(item?.event_end_date_time || item?.end_date_time)

	const time = startTime && endTime ? `${startTime} - ${endTime}` : ''
	const isLive = channelLive(item, type)

	if (isLive === false) {
		const relativeDate = dateUtils.formatDate(item?.event_start_date_time || item?.start_date_time)
		relativeDateString = relativeDate ? `${relativeDate}, ` : ''
	}

	metadata = relativeDateString + time
	return metadata
}

const getVideosMetadata = (item) => {
	const age = item?.card_age_restricted_to_age ? item.card_age_restricted_to_age : ''
	const ratingAdvisory = item?.card_rating_advisory ? item.card_rating_advisory : ''
	const genre = item?.card_genres ? item.card_genres.join(',').toUpperCase() : ''
	const genreString = genre.length > 1 && age.length > 1 ? ` | ${genre}` : genre
	return age + ratingAdvisory + genreString
}

const getDefaultMetadata = (item) => {
	const cardProgram = item?.card_program?.seasons?.[0]
	const video = cardProgram?.videos?.[0]
	const shortTitle = cardProgram?.displayCollectionShortTitle || ''
	const value = video?.metaData?.[1]?.value || ''
	const ratingAdvisory = video?.ratingAdvisory || ''

	const valueString = value.length > 1 && shortTitle.length > 1 ? ` | ${value}` : value
	const ratingAdvisoryString =
		ratingAdvisory.length > 1 && shortTitle.length > 1 ? ` | ${ratingAdvisory}` : ratingAdvisory

	return shortTitle + valueString + ratingAdvisoryString
}

const getAgeAndRating = (item) => {
	const age = item?.card_age_restricted_to_age || item?.event_rating
	const ratingAdvisory = item?.card_rating_advisory || ''
	return age + ' ' + ratingAdvisory.replace(/,/g, '')
}

// remove after
const getChannelTag = (item) => {
	const url = item.card_channel?.[0].href
	return url?.split('/').reverse()[0]
}

export const getHomeBillboardData = (item) => {
	let data = {}
	const type = item?.type?.toLowerCase()
	//Transform the data from the card info to billboard usable object
	switch (type) {
		//Recently watched channels - Live TV Channels

		case 'channels':
		case 'event': {
			const isLive = channelLive(item)
			data = {
				type: type,
				comingUpDate: dateUtils.formatDate(item?.start_date_time || item?.event_start_date_time),
				metadata: getChannelsMetadata(item, type),
				poster: getBillboardPosterURL(item, type),
				subtitle: item?.event_episode_title,
				synopsis: item?.event_synopsis || item?.synopsis,
				title: item?.event_main_title || item?.title,
				channelNumber: item?.number || item?.channel_number,
				channelLogo: getBillboardChannelLogoURL(item, type),
				showLogo: getBillboardShowLogoURL(item, type === 'channels' ? 'card' : type),
				rating: item?.event_rating,
				moreInfoEndpoint: item?.moreInfoEndpoint,
				label: item?.label,
				ageRating: getAgeAndRating(item),
				link: item?.links,
				channelTag: item?.channel_tag || item?.tag,
				genre: item?.channel_genres?.join(' | ') || item?.card_genres?.join(' | ') || item?.event_genres?.join(' | '),
				year: item?.card_release_year && item?.card_release_year !== 0 ? item?.card_release_year : '',
				isEventLive: isLive,
				endTime: item?.end_date_time || item?.event_end_date_time,
				isComingUp: isLive === false,
				fetched_info: item?.fetched_info,
			}
			return data
		}
		//Some VOD cards are videos
		case 'videos': {
			data = {
				type: type,
				airDate: item?.card_airDate,
				expiryDate: item?.card_expiryDate,
				metadata: getVideosMetadata(item),
				ageRating: getAgeAndRating(item),
				channelTag: getChannelTag(item),
				genre: item?.card_genres?.join(' | '),
				poster: getBillboardPosterURL(item, 'card'),
				showLogo: getBillboardShowLogoURL(item, 'card'),
				synopsis: item?.card_synopsis,
				title: item?.title,
				year: item?.card_release_year && item?.card_release_year !== 0 ? item?.card_release_year : '',
				trailerUrl: item?.trailerDetails,
				duration: item?.card_duration_in_seconds ?? undefined,
				fetched_info: item?.fetched_info,
			}
			return data
		}
		//Some are programs
		case 'layout':
		case 'programs': {
			// Program types with card_synopsis seem to only be on Continue Watching
			if (item.card_synopsis) {
				data = {
					type: type,
					airDate: item?.card_airDate,
					expiryDate: item?.card_expiryDate,
					metadata: getVideosMetadata(item),
					poster: getBillboardPosterURL(item, 'card'),
					showLogo: getBillboardShowLogoURL(item, 'card'),
					ageRating: getAgeAndRating(item),
					channelTag: getChannelTag(item),
					synopsis: item?.card_synopsis,
					title: item?.card_title,
					year: item?.card_release_year && item?.card_release_year !== 0 ? item?.card_release_year : '',
					seasonNumber: (item?.season_number || item?.card_season_number) ?? undefined,
					episodeNumber: (item?.episode_number || item?.card_episode_number) ?? undefined,
					genre: item?.card_genres?.join(' | '),
					trailerUrl: item?.trailerDetails,
					fetched_info: item?.fetched_info,
				}
				return data
			}
			//For all other program types NOT in Continue Watching
			else {
				data = {
					type: type,
					metadata: getDefaultMetadata(item),
					poster: item?.card_program?.images?.hero?.LARGE || getBillboardPosterURL(item, 'card'),
					showLogo: getBillboardShowLogoURL(item, 'card'),
					synopsis: item?.card_program?.seasons?.[0]?.videos?.[0]?.synopsis || item?.description,
					title: item?.title || item?.description,
					channelLogo: item?.card_program?.channels?.[0]?.images?.logos?.MEDIUM,
					channelNumber: item?.card_program?.channels?.[0]?.channelNumber,
					year: item?.card_release_year && item?.card_release_year !== 0 ? item?.card_release_year : '',
					seasonNumber: (item?.season_number || item?.card_season_number) ?? undefined,
					episodeNumber: (item?.episode_number || item?.card_episode_number) ?? undefined,
					genre: item?.card_genres?.join(' | '),
					ageRating: getAgeAndRating(item),
					trailerUrl: item?.trailerDetails,
					fetched_info: item?.fetched_info,
				}
				return data
			}
		}
	}
}

const getBillboardDetails = (item, type = null) => {
	let data = null

	if (type === 'home') {
		data = getHomeBillboardData(item)
	}
	if (type === 'livetv') {
		data = getLiveTVBillboardData(item)
	} else if (item?.program) {
		data = {
			year:
				item?.program?.seasons[0]?.videos?.[0]?.yearOfRelease &&
				item?.program?.seasons[0]?.videos?.[0]?.yearOfRelease !== 0
					? item?.program?.seasons[0]?.videos?.[0]?.yearOfRelease
					: '',
			genre: item?.program?.genres?.join(' | '),
			ageRating:
				item?.program?.seasons[0]?.videos?.[0]?.restrictionRating?.ageRestrictedToAge +
				' ' +
				item?.program?.seasons[0]?.videos?.[0]?.ratingAdvisory.replace(/,/g, ''),
			poster: item?.program?.images?.hero?.MEDIUM,
			synopsis: item?.program?.seasons?.[0]?.videos?.[0]?.synopsis,
			title: item?.program?.title,
			channelLogo: item?.program?.channels?.[0]?.images?.logos?.MEDIUM,
			channelNumber: item?.program?.channels?.[0]?.channelNumber,
			images: item?.program?.images,
		}
	} else if (item?.video) {
		const ageRating =
			item?.video?.restrictionRating?.ageRestrictedToAge + ' ' + item?.video?.ratingAdvisory.replace(/,/g, '')

		data = {
			year: item?.video?.yearOfRelease,
			genre: item?.video?.genres?.join(' | '),
			ageRating: ageRating,
			poster: item?.video?.images?.hero?.MEDIUM,
			synopsis: item?.video?.synopsis,
			title: item?.video?.title,
			channelLogo: item?.video?.channels?.[0]?.images?.logos?.MEDIUM,
			channelNumber: item?.video?.channels?.[0]?.channelNumber,
		}
	}
	return { ...data, moreInfoEndpoint: item?.moreInfoEndpoint }
}

export const getEpisodeDisplayItemTitle = (seasonNumber, episodeNumber, displayItemTitle) => {
	const title = displayItemTitle ? displayItemTitle : ''
	const episodeTitle = +episodeNumber && title ? `Ep${episodeNumber}: ${displayItemTitle}` : title
	return +seasonNumber && +episodeNumber && episodeTitle ? `S${seasonNumber}, ${episodeTitle}` : episodeTitle
}

export const getEpisodeTitle = (data) => {
	const seasonNumber = data?.seasonNumber || data?.season_number || data?.event_season_number
	const episodeNumber = data?.seasonEpisode || data?.episodeNumber || data?.event_episode_number
	const displayItemTitle = data?.displayItemTitle || data?.episodeTitle || data?.event_episode_title
	return getEpisodeDisplayItemTitle(seasonNumber, episodeNumber, displayItemTitle)
}

export const getSeasonEpisode = (seasonNumber, episodeNumber) => {
	const episodeTitle = episodeNumber ? `Ep${episodeNumber}` : ''
	return seasonNumber && episodeNumber ? `S${seasonNumber}, ${episodeTitle}` : episodeTitle
}

export const getVodDetails = (item, currentVideoId) => {
	let videoObj = null
	let resume_video_id = undefined
	if (item?.program) {
		if (currentVideoId) {
			videoObj = item?.program?.seasons?.flatMap((o) => o.videos)?.find((o) => o.genRef === currentVideoId)
		}

		if (!videoObj) {
			videoObj = item?.program?.seasons?.[0]?.videos?.[0]
		}

		resume_video_id = videoObj?.genRef
	} else if (item?.video) {
		videoObj = item.video
	} else {
		videoObj = item
		resume_video_id = videoObj?.genRef
	}

	const data = videoObj && {
		url: videoObj?.videoAssets?.[0]?.url,
		details: {
			...videoObj,
			title: videoObj?.displayTitle,
			episode_title: getEpisodeTitle(videoObj),
			contentId: videoObj?.videoAssets?.[0]?.manItemId,
			resumeVideoId: videoObj?.resume_video_id || resume_video_id,
			genref: currentVideoId ? getVideoIdByGenRef(item, currentVideoId) : getVideoId(item),
		},
		type: 'vod',
	}

	return data
}

export const getWatchlistDetails = (data, loading) => {
	let title = null
	if (loading) {
		title = 'Loading'
	} else if (data?.isWatchlistItem) {
		title = 'Added to My List'
	} else {
		title = 'Removed from My List'
	}
	return { ...data, title }
}

export const getBillboardData = (data, type = null, videoId = null) => {
	const billboardData = {
		billboardDetails: getBillboardDetails(data, type),
		vodDetails: getVodDetails(data),
		watchlistDetails: getWatchlistDetails(data?.preferences, false),
		episodeDetails: data?.program,
		movieDetails: data?.video,
	}
	return { data: billboardData, videoId, date: new Date().getTime() }
}

export const getNextEpisodeData = (episodeDetails, resumeVideoId) => {
	const videoData = resumeVideoId && episodeDetails?.seasons?.[0]?.videos?.find((item) => item.genRef === resumeVideoId)

	if (videoData) {
		return {
			synopsis: videoData.synopsis,
			displayItemDetailedTitle: videoData.displayItemDetailedTitle,
			durationInSeconds: videoData.durationInSeconds,
		}
	}
	return null
}
