import moment from 'moment'

export const formatTimeToHoursAndMinutes = (time) => {
	return moment(time).format('HH:mm')
}

export const getTimeBetweenDates = (date1, date2, unit = 'minutes') => {
	return date1.diff(date2, unit)
}

export const convertMinsToHrsMins = (minutes) => {
	if (minutes < 60) {
		return `${minutes}m left`
	} else {
		let hours = Math.floor(minutes / 60)
		let mins = minutes % 60
		return `${hours}h ${mins > 0 ? mins + 'm' : ''} left`
	}
}

export const convertSecsToHrsMins = (seconds) => {
	const hours = Math.floor(seconds / 3600)
	const mins = Math.floor((seconds % 3600) / 60)

	return `${hours > 0 ? `${hours}h ` : ''}${mins}m`
}

export const formatDate = (startDate) => {
	if (!startDate) return ''

	const now = moment()
	const start = moment(startDate)
	const diffDays = start.startOf('day').diff(now.startOf('day'), 'days')

	if (diffDays === 0) return 'Today'
	if (diffDays === 1) return 'Tomorrow'
	if (diffDays > 1) return start.format('MMMM D')

	return ''
}
