import styles from './NavSidebar.module.scss'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useFocusable, FocusContext, setFocus } from '@dstv-web-leanback/norigin-spatial-navigation'
import logo_fallback from '../../../assets/images/dstv_logo_fallback.png'
//Components
import { NavIcon, NavItem } from '@dstv-web-leanback/dstv-frontend-components'
import { NAV_REPLACE, MENU_TOGGLE, CLEAR_SEARCH, CLEAR_KEYBOARD } from '@dstv-web-leanback/dstv-frontend-services'

//Assets
import fallbackAvatar from '../../../assets/images/fallback-avatar.png'

export const NavSidebar = ({ activeProfile, items }) => {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const handleBack = () => {
		if (hasFocusedChild) {
			navigate('/home')
			setFocus('MENU_HOME')
		}
	}

	const { focusKey, hasFocusedChild, ref } = useFocusable({
		trackChildren: true,
		focusable: true,
		focusKey: 'MENU',
		onBackPress: handleBack,
	})

	// Effects
	useEffect(() => {
		setFocus('MENU_HOME')
	}, [setFocus, activeProfile])

	useEffect(() => {
		dispatch(MENU_TOGGLE({ toggled: hasFocusedChild }))
	}, [hasFocusedChild])

	// Functions
	const selectNavItem = (item) => {
		navigate(item.id)
		dispatch(NAV_REPLACE({ key: `MENU_${item.id.toUpperCase().replace(/ /g, '_')}` }))
	}

	const selectNavItemSearch = () => {
		navigate('/search')
		dispatch(CLEAR_KEYBOARD())
		dispatch(CLEAR_SEARCH())
	}
	const handleIconError = (e) => {
		e.target.onerror = null
		e.target.src = fallbackAvatar
	}

	return (
		<FocusContext.Provider value={focusKey}>
			<div ref={ref} className={`${styles.menu_container} ${!hasFocusedChild ? styles.menu_hidden : ''}`}>
				<header className={styles.menu_header}>
					{hasFocusedChild ? (
						<NavItem
							focusKey={'MENU'}
							id={'profiles'}
							icon={activeProfile?.avatar?.uri ? activeProfile.avatar.uri : fallbackAvatar}
							onIconError={handleIconError}
							itemEnterPress={() => navigate('/profiles')}
							title={'Profiles'}
						/>
					) : (
						<img src={logo_fallback} alt="DSTV Logo" className={styles.logo_fallback} />
					)}
				</header>

				<aside className={styles.menu}>
					<NavItem focusKey={'MENU'} id={'search'} itemEnterPress={() => selectNavItemSearch()} title={'Search'} />
					{items
						.filter((o) => o.id !== 'settings')
						.map((item, index) => {
							return (
								<NavItem
									focusKey={'MENU'}
									id={item.id}
									itemEnterPress={() => selectNavItem(item)}
									key={index}
									title={item.displayName}
								/>
							)
						})}
				</aside>
				<footer className={styles.menu_footer}>
					{items
						.filter((o) => o.id === 'settings')
						.map((item, index) => {
							return (
								<NavItem
									focusKey={'MENU'}
									id={item.id}
									itemEnterPress={() => selectNavItem(item)}
									key={index}
									title={item.displayName}
								/>
							)
						})}
				</footer>
			</div>

			<div className={`${styles.menu__overlay} ${!hasFocusedChild ? styles.overlay__hidden : ''}`}></div>
		</FocusContext.Provider>
	)
}
export default NavSidebar
