import { createSlice } from '@reduxjs/toolkit'
import { getHeaders, handleUnauthorized } from '../../utils/authHelper'
import { errorResponse, HTTP } from '../../utils/httpHelper'
import { ofType } from 'redux-observable'
import { mergeMap } from 'rxjs/operators'
import { of } from 'rxjs'
import { pageType } from '../../utils/constants'
import { getEndpointsFromMenu } from '../../utils/storageHelper'
import { getCardPosterImageURL, transformMenuItems } from '../../utils/transformHelper'
import { appendPageNumber, transformChannelgroups } from './helpers'

export const CATCHUP_FEATURE_KEY = 'catchup'

/*
 * Create our slice
 */
export const catchupSlice = createSlice({
	name: CATCHUP_FEATURE_KEY,
	initialState: { cards: [] },
	reducers: {
		SET_CATCHUP_ACTIVE_SECTION: (state, action) => {
			state.activeSection = action.payload
		},
		GET_CATCHUP: (state) => {
			delete state.error
			delete state.serverError
			delete state.activeSection
			state.loading = true
			state.cards = []
		},
		GET_CATCHUP_SUCCESS: (state, action) => {
			updateStateDetails(state, action)
		},
		GET_CATCHUP_WITH_FILTER: (state) => {
			delete state.error
			delete state.serverError
			state.loading = true
			state.cards = []
		},
		GET_CATCHUP_WITH_FILTER_SUCCESS: (state, action) => {
			updateStateDetails(state, action)
		},
		CATCHUP_PAGING: (state) => {
			delete state.error
			delete state.serverError
			state.loading = true
		},
		CATCHUP_PAGING_SUCCESS: (state, action) => {
			updateStateDetails(state, action)
		},
		GET_CATCHUP_ERROR: (state, action) => {
			state.error = action.payload.error
			state.serverError = action.payload.serverError
			state.loading = false
		},
	},
})

/*
 * Export reducer for store configuration.
 */
export const catchupReducer = catchupSlice.reducer

/*
 * Export actions
 */
export const {
	SET_CATCHUP_ACTIVE_SECTION,
	GET_CATCHUP,
	GET_CATCHUP_SUCCESS,
	GET_CATCHUP_WITH_FILTER,
	GET_CATCHUP_WITH_FILTER_SUCCESS,
	GET_CATCHUP_ERROR,
	CATCHUP_PAGING,
	CATCHUP_PAGING_SUCCESS,
} = catchupSlice.actions

/*
 * Set up the redux-observable epic
 */
export const catchupEpic = (action$) =>
	action$.pipe(ofType(GET_CATCHUP, GET_CATCHUP_WITH_FILTER, CATCHUP_PAGING), mergeMap(catchupService(action$)))

/*
 * Do API calls
 */
const catchupService = (action$) => (action) => {
	switch (action.type) {
		case GET_CATCHUP.type: {
			let endpoint = getEndpointsFromMenu(action.payload.category)
			return HTTP.GET(
				endpoint,
				getHeaders(),
				catchupSuccess(endpoint, action.payload.category),
				catchupError(action, pageType.catchup),
				true
			)
		}
		case GET_CATCHUP_WITH_FILTER.type: {
			return HTTP.GET(
				action.payload.url,
				getHeaders(),
				catchupWithFilterSuccess(action.payload.url),
				catchupError(action, pageType.catchup),
				true
			)
		}
		case CATCHUP_PAGING.type: {
			return HTTP.GET(
				appendPageNumber(action.payload.url, action.payload.page),
				getHeaders(),
				catchupSuccess(action.payload.url, 'paging'),
				catchupError(action, pageType.catchup),
				true
			)
		}
	}
}

/*
 * Dispatch actions based on API responses
 */

const catchupSuccess = (url, type) => (response) => {
	if (type === 'paging') {
		response.items.map((item) => {
			return [(item.poster_image = getCardPosterImageURL(item))]
		})

		return { type: CATCHUP_PAGING_SUCCESS.type, payload: { url: url, response: response } }
	} else if (response.subSections || type === 'mylist') {
		let menuItems = response.subSections && response.subSections?.filter((item) => item.name === 'Filter').pop().items
		//Cater for poster
		response.items.map((item) => [(item.poster_image = getCardPosterImageURL(item))])
		return {
			type: GET_CATCHUP_SUCCESS.type,
			payload: { url: url, response: response, menuItems: transformMenuItems(menuItems) },
		}
	} else {
		return { type: GET_CATCHUP_SUCCESS.type, payload: { url: url, channelgroups: response } }
	}
}

const catchupWithFilterSuccess = (url) => (response) => {
	//Cater for poster
	response.items.map((item) => [(item.poster_image = getCardPosterImageURL(item))])
	return { type: GET_CATCHUP_WITH_FILTER_SUCCESS.type, payload: { url: url, response: response } }
}

const catchupError = (action) => (response) => {
	return of(
		handleUnauthorized(
			response,
			{
				type: GET_CATCHUP_ERROR.type,
				payload: errorResponse(response, action),
			},
			action
		)
	)
}

const updateStateDetails = (state, action) => {
	state.url = action.payload.url
	if (action.payload.channelgroups) {
		state.loading = false
		state.channelgroups = transformChannelgroups(action.payload.channelgroups)
	} else {
		state.cards = [...state.cards, ...action.payload.response.items]
		state.count = action.payload.response.count
		state.loading = false
		state.page = action.payload.response.page
		state.total = action.payload.response.total
	}
}
