import { useEffect, useMemo, useRef, useState } from 'react'
import { useFocusable, FocusContext, setFocus } from '@dstv-web-leanback/norigin-spatial-navigation'
import { useDispatch, useSelector } from 'react-redux'

import {
	Iplate,
	PlayerButton,
	PlayerProgressBar,
	PlayerSettingsModal,
	PlayerThumbnail,
	Button,
	BackToLiveButton,
	TimeWidget,
} from '@dstv-web-leanback/dstv-frontend-components'
import {
	SAVE_AUDIO_LANGUAGE,
	SAVE_BANDWIDTH,
	useNavigation,
	CLOUD_DVR_KEY,
	GET_CURRENT_EVENT_BY_ID,
} from '@dstv-web-leanback/dstv-frontend-services'
import { useDebounce } from '@dstv-web-leanback/dstv-frontend-utils'

// Image assets
import iconCog from '../../../../assets/images/icons/icon-player-cog.svg'
import iconNext from '../../../../assets/images/icons/icon-player-next.svg'
import iconPause from '../../../../assets/images/icons/icon-player-pause.svg'
import iconPlay from '../../../../assets/images/icons/icon-player-play.svg'
import watchfromStartIcon from '../../../../assets/images/icons/icon-watch-from-start.svg'

import styles from '../PlayerControls.module.scss'
import {
	getDefaultSubOptionsValues,
	getPlayerSettingsOptions,
	convertSecondsToTime,
	getCurrentEpgEvent,
} from './helper'
import moment from 'moment'

export function LiveControls({
	audioLanguages,
	handleAudioChange,
	handleBandwidthChange,
	handleSubOptionChange,
	playing,
	playPause,
	switchChannel,
	seekTo,
	seekRange,
	liveSeekTime,
	handleBackToLive,
	currentTime,
	liveSeekTimeEnd,
	playbackCapabilities,
	dvrWatchfromStart,
}) {
	// Variables
	const CONTROLS_TIMEOUT = 7000
	const SEEK_INTERVAL = 10
	const [iplateActive, setIplateActive] = useState(false)
	const [prevChannel, setPrevChannel] = useState(undefined)
	const [nextChannel, setNextChannel] = useState(undefined)
	const [controlsVisible, setControlsVisible] = useState(true)
	const [showPlayerSettings, setShowPlayerSettings] = useState(false)
	const [seeking, setSeeking] = useState(false)
	const [seekAmount, setSeekAmount] = useState(0)
	const [seekTime, setSeekTime] = useState(liveSeekTime)
	const [isLiveStream, setIsLiveStream] = useState(true)
	const [currentEventId, setCurrentEventId] = useState('')
	const [time, setTime] = useState(moment())

	const dispatch = useDispatch()
	const { navigateBack } = useNavigation()

	const channelsState = useSelector((state) => state.channels)
	const playerState = useSelector((state) => state.player)
	const configState = useSelector((state) => state.config)
	const adPlaying = useSelector((state) => state.adPlayer.playing)

	const options = useRef([])
	let seekTimer = useRef()

	const { focusKey, focusSelf, ref } = useFocusable({
		focusKey: 'LIVE_CONTROLS',
		forceFocus: true,
		isFocusBoundary: true,
		preferredChildFocusKey: 'PLAY_PAUSE',
		onArrowPress: () => {
			debounce()
			setFocus('PLAY_PAUSE')
		},
		onBackPress: () => {
			if (controlsVisible) {
				hideControls()
			} else navigateBack()
		},
	})

	const debounce = useDebounce(CONTROLS_TIMEOUT, showControls, hideControls)

	// Effects
	useEffect(() => {
		focusSelf()
		debounce()
		document.addEventListener('keydown', handleKeyDown)
		return () => {
			document.removeEventListener('keydown', handleKeyDown)
			setShowPlayerSettings(false)
		}
	}, [])

	useEffect(() => {
		if (!adPlaying) debounce()
	}, [adPlaying])

	useEffect(() => {
		const index = channelsState.all_channels.findIndex(
			(channel) => channel.number === (playerState.details.channelNumber || playerState.details.number)
		)
		setPrevChannel(channelsState.all_channels[index - 1])
		setNextChannel(channelsState.all_channels[index + 1])
	}, [playerState.details.channelNumber])

	// When the controls become visible, set focus to the PLAY_PAUSE button
	useEffect(() => {
		if (controlsVisible) {
			setFocus('PLAY_PAUSE')
		}
	}, [controlsVisible])

	useEffect(() => {
		options.current = getPlayerSettingsOptions(configState, audioLanguages)

		if (!playerState.savedAudioLanguage && !playerState.savedBandwidth) {
			const defaultLanguage = audioLanguages?.[0]?.language
			const defaultBandwidth = +localStorage.getItem('QUALITY_STORAGE_BITRATE') || 6000000

			dispatch(SAVE_AUDIO_LANGUAGE({ language: defaultLanguage }))
			dispatch(SAVE_BANDWIDTH({ bandwidth: defaultBandwidth }))

			handleAudioChange(defaultLanguage)
			handleBandwidthChange(defaultBandwidth)
		}
	}, [])
	useEffect(() => {
		if (liveSeekTimeEnd === currentTime) {
			setIsLiveStream(true)
		}
	}, [liveSeekTimeEnd])

	useEffect(() => {
		if (controlsVisible && channelsState?.channels?.length > 0) {
			const targetEpgChannel = getCurrentEpgEvent(channelsState, playerState?.details?.channelTag)
			if (currentEventId !== '' && targetEpgChannel && currentEventId !== targetEpgChannel.id) {
				dispatch(GET_CURRENT_EVENT_BY_ID({ eventId: targetEpgChannel.id }))
			}
			setCurrentEventId(targetEpgChannel?.id)
		}
	}, [controlsVisible])

	const channelFallback = useMemo(() => {
		if (!playerState.details?.channelTag || playerState.channelLogo || playerState.details?.channelLogo) {
			return undefined
		}

		const channel = channelsState.all_channels?.find((o) => playerState.details?.channelTag === o.tag)

		return channel
	}, [playerState.details?.channelTag])

	// Functions
	const handleKeyDown = (e) => {
		switch (e.keyCode) {
			case 40: // Down arrow
				// disabled until fix is in place for iplate
				// setIplateActive(true)
				break
			default:
				break
		}
	}

	const onCogBtnSelect = () => {
		setShowPlayerSettings(!showPlayerSettings)
	}

	function showControls() {
		setControlsVisible(true)
		setTime(moment())
	}

	function hideControls() {
		setControlsVisible(false)
	}

	const handleSeek = (direction) => {
		debounce()
		showControls()
		setSeeking(true)

		const amount = direction === 'right' ? seekAmount + SEEK_INTERVAL : seekAmount - SEEK_INTERVAL
		setSeekAmount(amount)

		if (seekRange() <= liveSeekTime + amount) setSeekTime(seekRange())
		else if (liveSeekTime + amount <= 0) setSeekTime(0)
		else setSeekTime(liveSeekTime + amount)

		clearTimeout(seekTimer.current)
		seekTimer.current = setTimeout(() => {
			seekTo(seekAmount)
			setSeekAmount(0)
			setSeeking(false)
		}, 500)
	}
	const getPlayerProgress = () => {
		return (Math.abs(seekTime) / seekRange()) * 100
	}
	const getSeekProgress = () => {
		return (Math.abs(seekTime) / seekRange()) * 100
	}
	const getPlayerSeekTime = () => {
		return '-' + convertSecondsToTime(seekRange() - seekTime)
	}
	return (
		<FocusContext.Provider value={focusKey}>
			<div ref={ref} className={`${styles.live_controls_wrapper} ${controlsVisible && styles.visible}`}>
				{!iplateActive && controlsVisible && (
					<>
						<div className={styles.time_widget_wrapper}>
							<TimeWidget currentTime={time} player={true} />
						</div>
						<div className={styles.player_controls}>
							{seeking ? (
								<PlayerThumbnail
									currentTime={seekTime}
									duration={liveSeekTime}
									position={seeking ? getSeekProgress() : getPlayerProgress()}
									time={getPlayerSeekTime()}
									isLive={true}
								/>
							) : (
								<div className={styles.metadata_wrapper}>
									<div className={styles.channel_details}>
										{(playerState?.channelLogo || playerState?.details?.channelLogo || channelFallback?.logo) && (
											<img
												src={playerState?.channelLogo || playerState?.details?.channelLogo || channelFallback?.logo}
											></img>
										)}
										<h5>{playerState?.details?.channelNumber || channelFallback?.number}</h5>
									</div>
									<h1>{playerState.title ? playerState.title : playerState.details.title}</h1>
									<h3>{playerState.episode_title ? playerState.episode_title : playerState.details?.episode_title}</h3>
								</div>
							)}
							<div className={styles.times_wrapper}>
								<div className={styles.times_right}></div>
							</div>
							<div className={styles.progress_wrapper}>
								<PlayerProgressBar
									focusable={true}
									handleSeek={handleSeek}
									progress={seeking ? getSeekProgress() : getPlayerProgress()}
									seeking={seeking}
								/>
							</div>
							<div className={styles.controls_wrapper}>
								<div className={styles.controls_left}>
									<PlayerButton icon={iconCog} onSelect={onCogBtnSelect} selected={showPlayerSettings} />
								</div>
								<div className={styles.controls_center}>
									<div className={styles.prev_wrapper}>
										{prevChannel && (
											<PlayerButton
												icon={iconNext}
												iconReversed={true}
												onSelect={() => {
													switchChannel(prevChannel?.tag)
												}}
												label={prevChannel?.number}
												logo={prevChannel?.logo}
												round={false}
											/>
										)}
									</div>
									<PlayerButton
										focusKey={'PLAY_PAUSE'}
										icon={playing ? iconPause : iconPlay}
										onSelect={() => {
											setIsLiveStream(false)
											playPause()
										}}
									/>
									<div className={styles.next_wrapper}>
										{nextChannel && (
											<PlayerButton
												icon={iconNext}
												onSelect={() => {
													switchChannel(nextChannel?.tag)
												}}
												label={nextChannel?.number}
												logo={nextChannel?.logo}
												round={false}
											/>
										)}
									</div>
								</div>
								{configState?.data?.cloud_dvr_enabled?.flagValue === 'true' &&
									playbackCapabilities?.includes(CLOUD_DVR_KEY) && (
										<div className={styles.controls_right_livetv}>
											{getPlayerProgress() >= 97 && isLiveStream === true ? (
												<div className={styles.live_indicator}>
													<span className={styles.live_dot} />
													<span className={styles.title}>LIVE</span>
												</div>
											) : (
												<BackToLiveButton id={'Back_to_live'} title={'BACK TO LIVE'} onSelect={handleBackToLive} />
											)}
											{playerState?.details?.timeShiftUrl && (
												<div>
													<PlayerButton
														icon={watchfromStartIcon}
														label={'Watch from Start'}
														onSelect={dvrWatchfromStart}
														round={false}
														extraMargin={true}
													/>
												</div>
											)}
										</div>
									)}
							</div>
						</div>
					</>
				)}
				{showPlayerSettings && (
					<PlayerSettingsModal
						options={options.current}
						defaultValues={getDefaultSubOptionsValues(
							options.current,
							playerState.savedAudioLanguage,
							playerState?.savedBandwidth
						)}
						handleBackButton={() => {
							setShowPlayerSettings(null)
						}}
						handleSubOptionSelection={(optionHeading, subOption) => {
							setShowPlayerSettings(false)
							setControlsVisible(false)
							handleSubOptionChange(optionHeading, subOption)
						}}
					/>
				)}
			</div>
		</FocusContext.Provider>
	)
}
export default LiveControls
