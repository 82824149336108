import { createSlice } from '@reduxjs/toolkit'
import { mergeMap, filter, tap } from 'rxjs/operators'
import { of } from 'rxjs'

import { ENV } from '../../utils/environment'
import { HTTP } from '../../utils/httpHelper'
import { getHeaders, setTokenAndJWT } from '../../utils/authHelper'
import { fetchFromLocalStorage } from '../../utils/storageHelper'
import { secureLocalStorage } from '../../utils/secureStorage'
import { AUTH_KEY } from '../../utils/constants'
import { LOGOUT } from '../auth/auth.slice'
import { HARDWARE_ID } from '../../utils/constants'
import { userLogout } from './helper'

export const SESSION_FEATURE_KEY = 'session'

/*
 * Create our slice
 */
export const sessionSlice = createSlice({
	name: SESSION_FEATURE_KEY,
	initialState: {},
	reducers: {
		SESSION_REFRESH: (state) => {
			state.loading = true
		},
		SESSION_REFRESH_SUCCESS: (state) => {
			state.loading = false
		},
		SESSION_REFRESH_ERROR: (state) => {
			state.loading = false
		},
	},
})

/*
 * Export reducer for store configuration.
 */
export const sessionReducer = sessionSlice.reducer

/*
 * Export actions
 */
export const { SESSION_REFRESH, SESSION_REFRESH_SUCCESS, SESSION_REFRESH_ERROR } = sessionSlice.actions

/*
 * Set up the redux-observable epic
 */
export const sessionEpic = (action$) => action$.pipe(filter(SESSION_REFRESH.match), mergeMap(sessionService(action$)))

/*
 * Do API calls
 */
const sessionService = (action$) => (action) => {
	let payload = fetchFromLocalStorage(AUTH_KEY)
	return HTTP.POST(
		ENV.REFRESH_TOKEN_API,
		payload,
		getHeaders(),
		sessionSuccess(action.payload),
		sessionError(action),
		true
	)
}

/*
 * Dispatch actions based on API responses
 */

const sessionSuccess = (payload) => (response) => {
	if (response) {
		setTokenAndJWT(response.response || response)

		if (payload && payload.payload && payload.payload.retryAction && payload.payload.retryAction.type)
			return payload.payload.retryAction
		else if (payload && payload.type) return { type: payload.type, payload: payload.payload }
		return {
			type: SESSION_REFRESH_SUCCESS.type,
			payload: payload,
		}
	}
}
const sessionError = (action) => (response) => {
	action.count = action.count || 1
	if (action.count < 3) {
		return of({
			type: SESSION_REFRESH.type,
			payload: action.payload,
			count: action.count + 1,
		})
	}

	userLogout()
	window.location.reload()

	return of({
		type: LOGOUT.type,
		payload: {
			response: response,
			description: 'unable to refresh session',
		},
	})
}
