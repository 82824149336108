import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFocusable, getCurrentFocusKey } from '@dstv-web-leanback/norigin-spatial-navigation'
import { TrailerPlayer, navigateToShowPage, getTimePassedInSeconds } from '@dstv-web-leanback/dstv-frontend-components'
import { NAV_PUSH, getVideoId, useNavigation } from '@dstv-web-leanback/dstv-frontend-services'
import poster_fallback_landscape from '../../../../assets/images/smarttv/poster_fallback_landscape.svg'
import poster_fallback from '../../../../assets/images/smarttv/poster_fallback.svg'
import styles from './ExpandableCard.module.scss'
import { useIntersectionObserver } from '@dstv-web-leanback/dstv-frontend-utils'
import Image from '../../../core/Image/LazyImage'

export const ExpandableCard = ({
	index,
	carouselIndex,
	features,
	rowTitle,
	item,
	rowId,
	key,
	cardType,
	itemFocus,
	from,
	...rest
}) => {
	const dispatch = useDispatch()
	const billboardState = useSelector((state) => state.billboard)
	const { location, navigate } = useNavigation()
	const [stateVideo, setStateVideo] = useState(false)
	const [trailerDetails, setTrailerDetails] = useState('')
	const [isTrailerPlaying, setIsTrailerPlaying] = useState(false)
	const [playStartTime, setPlayStartTime] = useState(null)
	const { className } = rest
	const { ref, focused } = useFocusable({
		onFocus: () => {
			handleVideoPlayback(false)
			if (ref) itemFocus(ref, item, index)

			if (rest.getCardDetails && !item.fetched_info) {
				getCardDetails(item, rowId, index)
			}
		},
		onEnterPress: () => {
			let autoplayTime
			if (features?.includes('play_in_billboard') || features?.includes('play_in_container')) {
				autoplayTime = getTimePassedInSeconds(billboardState.playStartTime)
			} else if (features?.includes('play_in_card')) {
				autoplayTime = getTimePassedInSeconds(playStartTime)
			}
			dispatch(
				NAV_PUSH({
					...item,
					rowTitle,
					features,
					autoplayTime,
					key: getCurrentFocusKey(),
					location: location.pathname,
					rowIndex: carouselIndex,
					rowId: rowId,
					id: item?.id,
					index: index,
				})
			)
			navigateToShowPage(item, location, navigate, getVideoId)
		},
		focusKey: `CARD_${rowId ? rowId : ''}${index}`,
	})

	const isIntersecting = useIntersectionObserver(ref)

	const { getCardDetails } = rest

	useEffect(() => {
		if (index === 0 && item?.tracking_info?.row === 0) getCardDetails(item, rowId)
	}, [])

	const handleVideoPlayback = (data) => {
		setStateVideo(data)
		setIsTrailerPlaying(false)
	}

	const handleTrailerStart = (video) => {
		video.play()
		setIsTrailerPlaying(true)
		const startTime = new Date()
		setPlayStartTime(startTime)
	}
	useEffect(() => {
		if (item && item?.trailerDetails) {
			setTrailerDetails(item?.trailerDetails)
		}
	}, [item])

	return (
		<div
			ref={ref}
			className={`${styles.expandable_card_container} ${focused ? styles.active : ''} ${className ? className : ''}`}
		>
			<div className={`${focused ? styles.expandablecard : styles.card}`}>
				{isIntersecting && (
					<>
						<Image
							className={`${styles.card_image} ${!focused ? styles.active : ''}`}
							src={item?.poster_image || poster_fallback}
							alt={item?.title || ''}
							loading="lazy"
							fallback={poster_fallback}
						/>
						{!isTrailerPlaying && (
							<Image
								className={`${styles.card_image} ${focused ? styles.active : ''}`}
								src={item?.poster_image_landscape || poster_fallback_landscape}
								alt={item?.title || ''}
								fallback={poster_fallback_landscape}
							/>
						)}
						{focused && !stateVideo && trailerDetails ? (
							<TrailerPlayer
								url={trailerDetails}
								videoEnd={handleVideoPlayback}
								cardType={cardType}
								className={focused ? styles.active : ''}
								onPlay={handleTrailerStart}
							/>
						) : (
							''
						)}
					</>
				)}
			</div>
			{isTrailerPlaying && focused && <div className={styles.expandable_title}>{item.title}</div>}
		</div>
	)
}
export default ExpandableCard
