import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFocusable, getCurrentFocusKey } from '@dstv-web-leanback/norigin-spatial-navigation'
import {
	navigateToShowPage,
	getTimePassedInSeconds,
	navigateToContentPage,
} from '@dstv-web-leanback/dstv-frontend-components'
import {
	NAV_PUSH,
	getVideoId,
	useNavigation,
	replaceVirtualLinks,
	GET_HOME,
} from '@dstv-web-leanback/dstv-frontend-services'
import poster_fallback from '../../../../assets/images/smarttv/poster_fallback.svg'
import styles from './CardPortrait.module.scss'
import { useIntersectionObserver } from '@dstv-web-leanback/dstv-frontend-utils'
import Image from '../../../core/Image/LazyImage'

export const CardPortrait = ({
	index,
	carouselIndex,
	features,
	rowTitle,
	item,
	rowId,
	key,
	cardType,
	itemFocus,
	from,
	...rest
}) => {
	const dispatch = useDispatch()
	const billboardState = useSelector((state) => state.billboard)
	const { location, navigate } = useNavigation()
	const [stateVideo, setStateVideo] = useState(false)
	const [trailerDetails, setTrailerDetails] = useState('')
	const [isTrailerPlaying, setIsTrailerPlaying] = useState(false)
	const [playStartTime, setPlayStartTime] = useState(null)
	const { className } = rest
	const { ref, focused } = useFocusable({
		onFocus: () => {
			handleVideoPlayback(false)
			if (ref) itemFocus(ref, item, index)

			if (rest.getCardDetails && !item.fetched_info) {
				getCardDetails(item, rowId, index)
			}
		},
		onEnterPress: () => {
			if (item?.type === 'layout') {
				dispatch(
					NAV_PUSH({
						key: getCurrentFocusKey(),
						location: location.pathname,
						rowIndex: carouselIndex,
						rowId: rowId,
						id: item?.id,
						index: index,
					})
				)
				dispatch(GET_HOME({ url: replaceVirtualLinks(item.links[0].href) }))
				navigateToContentPage(item, location, navigate)
			} else {
				let autoplayTime
				if (features?.includes('play_in_billboard') || features?.includes('play_in_container')) {
					autoplayTime = getTimePassedInSeconds(billboardState.playStartTime)
				} else if (features?.includes('play_in_card')) {
					autoplayTime = getTimePassedInSeconds(playStartTime)
				}
				dispatch(
					NAV_PUSH({
						...item,
						carouselIndex,
						rowTitle,
						features,
						autoplayTime,
						key: getCurrentFocusKey(),
						location: location.pathname,
						rowIndex: carouselIndex,
						rowId: rowId,
						id: item?.id,
						index: index,
					})
				)
				navigateToShowPage(item, location, navigate, getVideoId)
			}
		},
		focusKey: `CARD_${rowId ? rowId : ''}${index}`,
	})

	const isIntersecting = useIntersectionObserver(ref)

	const { getCardDetails } = rest

	useEffect(() => {
		if (index === 0 && item?.tracking_info?.row === 0) getCardDetails(item, rowId)
	}, [])

	const posternumbered = cardType === 'posternumbered'

	const handleVideoPlayback = (data) => {
		setStateVideo(data)
		setIsTrailerPlaying(false)
	}

	useEffect(() => {
		if (item && item?.trailerDetails) {
			setTrailerDetails(item?.trailerDetails)
		}
	}, [item])

	return (
		<div
			ref={ref}
			className={`${styles.card_container} ${focused ? styles.active : ''} ${className}  ${
				posternumbered && styles.numberedCard
			}`}
		>
			{posternumbered && <span className={styles.number}>{index + 1}</span>}
			<div className={styles.card}>
				{isIntersecting && (
					<Image
						className={styles['card_image']}
						src={item?.poster_image ?? poster_fallback}
						alt={item?.title || ''}
						loading="lazy"
						fallback={poster_fallback}
					/>
				)}
			</div>
		</div>
	)
}
export default CardPortrait
