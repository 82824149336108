import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFocusable, FocusContext, setFocus } from '@dstv-web-leanback/norigin-spatial-navigation'

import { Carousel } from '@dstv-web-leanback/dstv-frontend-components'
import {
	SET_ACTIVE_SECTION,
	SET_CATCHUP_ACTIVE_SECTION,
	useNavigation,
} from '@dstv-web-leanback/dstv-frontend-services'

import styles from './ChannelsMenu.module.scss'

export function ChannelsMenu({ gridFocused, onSectionChange }) {
	const { channelgroups, activeSection } = useSelector((state) => state.catchup)

	const dispatch = useDispatch()
	const { location } = useNavigation()

	const { focusKey, ref } = useFocusable({
		focusKey: 'SECTION_MENU',
		preferredChildFocusKey: activeSection,
	})

	useEffect(() => {
		if (!location.back) {
			setFocus('SECTION_MENU')
		}
	}, [channelgroups, location.back])

	const changeChannelMenuFocus = (wrapper, carouselIndex, item, id, index) => {
		dispatch(SET_ACTIVE_SECTION({ index: index }))
		dispatch(SET_CATCHUP_ACTIVE_SECTION(`SUBMENU_${item?.channelName.toUpperCase()}`))
		onSectionChange && onSectionChange(index)
	}

	return (
		<FocusContext.Provider value={focusKey}>
			<div ref={ref} className={`${styles.channels_menu} ${gridFocused && styles.hidden}`}>
				<Carousel
					cards={channelgroups}
					type="channelgroups"
					onCarouselFocus={changeChannelMenuFocus}
					saveLastFocusedChild={true}
				/>
			</div>
		</FocusContext.Provider>
	)
}
export default ChannelsMenu
