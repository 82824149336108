import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FocusContext, useFocusable, setFocus } from '@dstv-web-leanback/norigin-spatial-navigation'

import {
	Billboard,
	CatchupContainer,
	Loader,
	DialogFull,
	iconEmptyWatchlist,
} from '@dstv-web-leanback/dstv-frontend-components'
import {
	getBillboardData,
	CLEAR_BILLBOARD_INFO,
	NAV_POP,
	useNavigation,
	WATCH_LIST,
	WATCH_LIST_PAGING,
	WATCH_LIST_FEATURE_KEY,
	SET_BILLBOARD_INFO,
	BILLBOARD_FEATURE_KEY,
} from '@dstv-web-leanback/dstv-frontend-services'

import { focusMainNavigation } from '../../utils/helper'
import styles from './Watchlist.module.scss'

export const Watchlist = (props) => {
	const dispatch = useDispatch()
	const { location, navigateBack } = useNavigation()

	const watchlistState = useSelector((state) => state[WATCH_LIST_FEATURE_KEY])
	const billboardState = useSelector((state) => state[BILLBOARD_FEATURE_KEY])

	const [gridFocused, setGridFocused] = useState(false)

	// Effects
	useEffect(() => {
		initWatchlist()
		if (location.back) {
			if (location.focusKey) {
				setFocus(location.focusKey)
				dispatch(NAV_POP())
			}

			// Previously focused card has been removed from the user's watchlist, set focus to first card instead
			if (!hasFocusedChild) {
				focusSelf()
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.pathname, location.back, location.focusKey])

	useEffect(() => {
		dispatch(CLEAR_BILLBOARD_INFO())

		if (watchlistState?.data?.items?.length === 0) {
			dispatch(CLEAR_BILLBOARD_INFO())
		}

		if (watchlistState?.data?.items?.length) {
			updateBillboard(watchlistState.data?.items?.[0])
			setFocus('CARD_0')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [watchlistState?.data])

	// Functions
	const initWatchlist = () => {
		let currentSection = location.pathname.split('/')[1]
		dispatch(WATCH_LIST({ category: currentSection }))
	}

	const updateBillboard = (item) => {
		const billboardData = getBillboardData(item)
		const details = billboardData?.data?.billboardDetails
		dispatch(SET_BILLBOARD_INFO({ details }))
	}

	const paginateWatchlistGrid = () => {
		dispatch(
			WATCH_LIST_PAGING({
				page: watchlistState.data.page + 1,
			})
		)
	}

	const { focusKey, ref, focusSelf, hasFocusedChild } = useFocusable({
		focusKey: 'MY_LIST',
		onBackPress: () => {
			focusMainNavigation()
		},
		trackChildren: true,
	})

	if (watchlistState.error) {
		return (
			<DialogFull
				error={watchlistState.error}
				serverError={watchlistState.serverError}
				backAction={() => {
					navigateBack()
				}}
				retryAction={() => {
					initWatchlist()
					focusMainNavigation()
				}}
			/>
		)
	}

	return (
		<FocusContext.Provider value={focusKey}>
			<div ref={ref}>
				{watchlistState?.loading && <Loader />}
				{watchlistState?.data?.total === 0 && (
					<div className={styles.no_content_container}>
						<img alt="" className={styles.no_content_image} src={iconEmptyWatchlist} />
						<div className={styles.no_content_title}>Items added to your list will appear here.</div>
					</div>
				)}
				{watchlistState?.data && !watchlistState?.loading && watchlistState?.data?.items?.length > 0 && (
					<Billboard detailsVisible={gridFocused} billboardInfo={billboardState.info} />
				)}
				{watchlistState?.data?.items && (
					<CatchupContainer
						setGridFocused={setGridFocused}
						cards={watchlistState?.data?.items}
						changeGridFocus={updateBillboard}
						focusKeyProp="WATCHLIST_GRID"
						paginateCatchupGrid={paginateWatchlistGrid}
					/>
				)}
			</div>
		</FocusContext.Provider>
	)
}
export default Watchlist
