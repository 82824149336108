import { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react'
import * as shaka from 'shaka-player'

import { useSelector } from 'react-redux'

export const ShakaPlayer = forwardRef(({ url, config, onLoaded, onError, onBuffer, ...rest }, ref) => {
	const videoRef = useRef(null)

	const [player, setPlayer] = useState(null)
	const { reloadStream } = useSelector((state) => state.adPlayer)
	const { bookmark } = useSelector((state) => state.player)

	useEffect(() => {
		shaka.polyfill.installAll()
		const player = new shaka.Player(videoRef.current)
		setPlayer(player)

		player.addEventListener('error', handleStreamingError)
		player.addEventListener('buffering', onBuffer)
		player.addEventListener('stalldetected', onStallDetected)

		return () => {
			player.removeEventListener('error', handleStreamingError)
			player.removeEventListener('buffering', onBuffer)
			player.removeEventListener('stalldetected', onStallDetected)
			player.destroy()
		}
	}, [])

	// configure shaka
	useEffect(() => {
		if (player && config) {
			player.configure(config)
		}
	}, [player, config])

	// load stream
	useEffect(() => {
		if (player && url) {
			loadUrl(url)
		}
	}, [player, url])

	// reload stream
	useEffect(() => {
		if (player && url) loadUrl(url)
	}, [reloadStream])

	useImperativeHandle(
		ref,
		() => ({
			get player() {
				return player
			},
			get videoElement() {
				return videoRef.current
			},
		}),
		[player]
	)

	const loadUrl = async (url) => {
		try {
			await player.load(url, bookmark)
			if (onLoaded) {
				onLoaded(videoRef.current)
			} else {
				await videoRef.current.play()
			}
		} catch (e) {
			throwError?.(e)
		}
	}

	function throwError(error) {
		onError(error)
	}

	function onStallDetected(evt) {
		console.warn(evt)
	}

	function handleStreamingError(error) {
		if (error instanceof Error) {
			// shaka crashed with an unhandled native error
			throwError(error)
		}

		if (error.detail.severity === shaka.util.Error.Severity.CRITICAL) {
			// handle fatal error, playback can not continue
			throwError(error)
		} else {
			// handle non-fatal error, playback can continue
			player.retryStreaming()
		}
	}

	return <video ref={videoRef} {...rest} />
})

export default ShakaPlayer
