import { useEffect, useState } from 'react'
import { useFocusable, FocusContext, setFocus } from '@dstv-web-leanback/norigin-spatial-navigation'
import { ProgressBar } from '@dstv-web-leanback/dstv-frontend-components'
import posterFallback from '../../../assets/images/smarttv/poster_fallback.png'
import { ModalItem } from '@dstv-web-leanback/dstv-frontend-components'
import styles from './ModalOverlay.module.scss'
import { dateUtils } from '@dstv-web-leanback/dstv-frontend-utils'
export const ModalOverlay = ({ progress, image, title, remainingTime, options, handleBack }) => {
	const [focusable, setFocusable] = useState(false)
	const { focusKey, ref } = useFocusable({
		focusKey: 'MODAL',
		isFocusBoundary: true,
		onBackPress: handleBack,
	})
	useEffect(() => {
		setFocus(focusKey)
	}, [])

	return (
		<div className={styles.modal_container}>
			<div className={styles.modal_poster}>
				<img
					src={image}
					className={styles.modal_poster_image}
					alt="Card Poster"
					style={{ backgroundImage: `url(${posterFallback})` }}
					loading="lazy"
				/>
				<div className={styles.remaining_time_container}>
					<div className={styles.remaining_time}>{dateUtils.convertMinsToHrsMins(remainingTime)}</div>
				</div>
				<div className={styles.progressbar_container}>
					{progress > -1 && (
						<div className={styles.progressbar_wrapper}>
							<ProgressBar progress={progress} />
						</div>
					)}
				</div>
			</div>
			<div className={styles.modal_title} loading="lazy">
				<p>{title.length > 20 ? `${title.substring(0, 20)} ...` : title}</p>
			</div>
			<FocusContext.Provider value={focusKey}>
				<div ref={ref} className={styles.modal}>
					{options.map((option, i) => (
						<ModalItem {...option} index={i} focusable={focusable} handleRelease={() => setFocusable(true)} />
					))}
				</div>
			</FocusContext.Provider>
		</div>
	)
}
export default ModalOverlay
