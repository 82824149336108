import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styles from './Billboard.module.scss'
import showpageFallbackImgCardInfo from '../../../assets/images/streamaBillboardFallback.png'
import { useDebounce, dateUtils } from '@dstv-web-leanback/dstv-frontend-utils'
import { getTimeLeft, SET_BILLBOARD_TRAILER_START } from '@dstv-web-leanback/dstv-frontend-services'
import TrailerPlayer from '../TrailerPlayer/TrailerPlayer'
import { resumeFocused } from '@dstv-web-leanback/dstv-frontend-components'
import PreviewPlayer from '../PreviewPlayer/PreviewPlayer'

/**
 * @typedef {Object} BillboardInfo
 * @property {string} title
 * @property {string} channelLogo
 * @property {string} showLogo
 * @property {image} image
 * @property {string} synopsis
 */

/**
 * @typedef {Object} BillboardProps
 * @property {boolean} detailsVisible
 * @property {BillboardInfo} billboardInfo
 * @property {boolean} card
 * @property {(loading: boolean) => void} onLoad
 * @property {boolean} playbackEnabled
 * @property {boolean} showMetadata
 * @property {boolean} showButtonLabel
 */

/**
 * @param {BillboardProps} props
 * @returns {JSX.Element}
 */
export function Billboard({
	detailsVisible = false,
	billboardInfo = undefined,
	card = false,
	onLoad,
	playbackEnabled = true,
	showMetadata = false,
	showButtonLabel = false,
	centered = false,
}) {
	const dispatch = useDispatch()

	const [info, setInfo] = useState()
	const [imageSrc, setImageSrc] = useState()
	const [isTrailerPlaying, setIsTrailerPlaying] = useState(false)
	const [canPlayPreview, setCanPlayPreview] = useState(false)
	const [logoSrc, setLogoSrc] = useState()
	const [trailerUrl, setTrailerUrl] = useState(undefined)

	// remove after consolidated api
	const allChannels = useSelector((state) => state.channels.all_channels)

	const handlePlaybackEnd = () => {
		setTrailerUrl(undefined)
		setIsTrailerPlaying(false)
	}

	const handlePlaybackStart = (video) => {
		setIsTrailerPlaying(true)
		video.play()

		const playStartTime = new Date()
		dispatch(SET_BILLBOARD_TRAILER_START({ playStartTime: playStartTime }))
	}

	const shouldPlayPreview = useMemo(
		() =>
			playbackEnabled &&
			!billboardInfo?.isComingUp &&
			(billboardInfo?.type === 'live' ||
				billboardInfo?.type === 'event' ||
				(billboardInfo?.livePreview && billboardInfo?.isEventLive)),
		[
			billboardInfo?.type,
			playbackEnabled,
			billboardInfo?.livePreview,
			billboardInfo?.isEventLive,
			billboardInfo?.isComingUp,
		]
	)

	const shouldPlayTrailer = useMemo(
		() => playbackEnabled && billboardInfo?.type !== 'live' && !billboardInfo?.livePreview && !shouldPlayPreview,
		[playbackEnabled, billboardInfo?.type, shouldPlayPreview]
	)

	// remove after consolidated api
	function addChannelInfo(info) {
		const channel = allChannels?.find((channel) => channel.tag === info?.channelTag)

		return {
			...info,
			channelLogo: info?.channelLogo ?? channel?.logo,
			channelNumber: info?.channelNumber ?? channel?.number,
		}
	}

	const posterDebounce = useDebounce(
		200,
		() => {
			setTrailerUrl(undefined)
			setIsTrailerPlaying(false)
			setCanPlayPreview(false)
			onLoad?.(true)
		},
		() => {
			const poster = billboardInfo?.poster ?? billboardInfo?.images?.poster?.LARGE
			const logoUrl = billboardInfo?.showLogo ?? billboardInfo?.images?.['show-logo']?.['XLARGE']

			if (poster || logoUrl) {
				const img = new Image()

				img.onload = () => {
					const imageUrlWithSpaces = poster
					const imageUrl = imageUrlWithSpaces ? imageUrlWithSpaces.replace(/ /g, '%20') : null

					setImageSrc(imageUrl)
					setLogoSrc(logoUrl)
					setInfo(addChannelInfo(billboardInfo))
					setCanPlayPreview(true)
					onLoad?.(false)
				}
				img.onerror = (error) => {
					setImageSrc(showpageFallbackImgCardInfo)
					setCanPlayPreview(true)
					onLoad?.(false)
					setInfo(addChannelInfo(billboardInfo))
				}
				img.src = poster

				return () => {
					img.onload = undefined
					img.onerror = undefined
					img.src = ''
				}
			} else if (billboardInfo?.synopsis || billboardInfo?.fetched_info) {
				setImageSrc(showpageFallbackImgCardInfo)
				setLogoSrc(logoUrl ? logoUrl : undefined)
				setInfo(addChannelInfo(billboardInfo))
				setCanPlayPreview(true)
				onLoad?.(false)
			}
		}
	)

	const buttonText = billboardInfo?.isEventLive
		? `Watch ${billboardInfo?.label?.id === 'live' ? 'Live' : 'Now'}`
		: billboardInfo?.isComingUp
		? 'Coming Up'
		: 'Watch'

	function startTrailer(url) {
		setTrailerUrl(url)
	}

	const trailerDebounce = useDebounce(3000, undefined, startTrailer)

	useEffect(() => {
		posterDebounce()
		trailerDebounce(billboardInfo?.trailerUrl)
	}, [
		billboardInfo?.poster,
		billboardInfo?.metadata,
		billboardInfo?.synopsis,
		billboardInfo?.trailerUrl,
		billboardInfo?.channelTag,
	])

	return (
		<section className={`${styles.billboard} ${card ? styles.card : ''} ${centered ? styles.full_height : ''}`}>
			<div className={`${styles.hero_image_wrapper_lrg}`}>
				{imageSrc && <div style={{ backgroundImage: `url(${imageSrc})` }} className={styles.hero_image_wrapper}></div>}
			</div>

			{shouldPlayPreview && canPlayPreview && (
				<div className={styles.player}>
					<PreviewPlayer item={info} onPlay={handlePlaybackStart} videoEnd={handlePlaybackEnd} />
				</div>
			)}
			{shouldPlayTrailer && trailerUrl && (
				<div className={styles.player}>
					<TrailerPlayer url={trailerUrl} videoEnd={handlePlaybackEnd} onPlay={handlePlaybackStart} />
				</div>
			)}

			<div className={`${styles['container-fluid']} ${centered ? styles.centered : ''}`}>
				<div className={`${styles.billboard_details} ${!detailsVisible && styles.hide}`}>
					<div className={styles.billboard__inner}>
						{/* logo */}
						{info?.channelLogo && (
							<div className={styles.channelWrap}>
								<div className={styles.channelLogo}>
									<img src={info?.channelLogo} alt="Channel Logo" />
								</div>

								<p className={styles.channelNumber}>{info?.channelNumber ? info?.channelNumber : ''}</p>
							</div>
						)}

						{/* title */}
						{logoSrc ? (
							<img className={styles.showLogo} src={logoSrc} onError={() => setLogoSrc(undefined)} alt="Show Logo" />
						) : (
							<h1 className={styles.header}>{info?.title}</h1>
						)}

						<div className={`${isTrailerPlaying ? styles.fade_out_move_info_with_delay : styles.fade_in_move_info}`}>
							{/* Display Item Detailed Title */}
							<p className={styles.bookmark_details}>{info?.displayItemDetailedTitle}</p>

							{/* Metadata */}
							{showMetadata && info?.isComingUp !== undefined ? (
								<div className={styles.metadata_movies}>
									{info?.metadata?.length && (
										<p className={[styles.metadata, styles.live_metadata].join(' ')}>
											{info?.isComingUp && <span className={styles.coming_up_label}>Coming Up</span>}
											<span className={styles.label_wrapper}>
												<span>
													{info?.metadata}
													{info?.isEventLive ? getTimeLeft(info) : ''}
												</span>
											</span>
										</p>
									)}
								</div>
							) : null}

							{info?.isComingUp === undefined && (
								<div className={styles.metadata_movies}>
									{info?.year && <p className={styles.metadata}>{info?.year}</p>}
									{(info?.type === 'programs' || info?.type === 'layout') &&
									info?.seasonNumber !== undefined &&
									info?.episodeNumber !== undefined ? (
										<p className={styles.metadata}>
											S{info?.seasonNumber} Ep{info?.episodeNumber}
										</p>
									) : null}
									{info?.type === 'videos' && info?.duration >= 60 ? (
										<p className={styles.metadata}>{dateUtils.convertSecsToHrsMins(info.duration)}</p>
									) : null}
									{info?.genre && <p className={styles.metadata}>{info?.genre}</p>}
								</div>
							)}

							{/* Synopsis */}
							<p className={`${info?.metadata ? styles.synopsisShort : styles.synopsisFull}`}>{info?.synopsis}</p>

							{/* Age Rating */}
							{info?.ageRating && info?.ageRating.trim() !== '' && info?.ageRating !== 'undefined ' && (
								<div className={styles.ageRating}>{info?.ageRating}</div>
							)}
						</div>

						{/* buttons should show regardless of debounce so we are using non debounced values */}
						{showButtonLabel ? (
							<div>
								<button className={styles.buttons_wrapper} id={1} key={1}>
									{!billboardInfo?.isComingUp && <img className={styles.play_buttonsmall} alt="" src={resumeFocused} />}
									{buttonText}
								</button>
							</div>
						) : (
							billboardInfo?.isSingleEvent &&
							info?.isSingleEvent && (
								<div>
									<button className={styles.buttons_wrapper} id={1} key={1} title={'Watch'}>
										{!billboardInfo?.isComingUp && (
											<img className={styles.play_buttonsmall} alt="" src={resumeFocused} />
										)}
										{buttonText}
									</button>
								</div>
							)
						)}
					</div>
				</div>
			</div>
		</section>
	)
}

export default Billboard
