import { ShakaPlayer, getPlayerConfiguration, getStreamUrl } from '@dstv-web-leanback/dstv-frontend-components'
import { GET_CDN_TOKEN, GET_CDN_TOKEN_SUCCESS, useEntitlementRefresh } from '@dstv-web-leanback/dstv-frontend-services'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styles from './PreviewPlayer.module.scss'

export function PreviewPlayer({ item, videoEnd, onPlay }) {
	const shakaPlayer = useRef(null)
	const dispatch = useDispatch()
	const { player } = shakaPlayer.current || {}
	const cdnAuthState = useSelector((state) => state.cdn_auth)
	const configState = useSelector((state) => state.config.data)
	const { entitlementState } = useEntitlementRefresh(player)
	const chanId = item?.channelTag
	const streamUrl = item?.link?.find((link) => link?.rel?.includes('stream'))?.href

	const [licenseFailed, setLicenseFailed] = useState(false)

	useEffect(() => {
		if (!licenseFailed && chanId) {
			dispatch(GET_CDN_TOKEN_SUCCESS({ accessToken: null, channelTag: null, loading: true }))
			dispatch(GET_CDN_TOKEN({ channelTag: chanId }))
		}
	}, [chanId, licenseFailed, dispatch])

	const [trailerUrl, trailerConfig] = useMemo(() => {
		if (entitlementState.error || licenseFailed || !cdnAuthState?.accessToken) {
			return [null, null]
		}

		if (!entitlementState.data?.irdetoSession?.sessionId || !entitlementState.data?.ucp_filter) {
			return [null, null]
		}

		const playerPackage = {
			type: 'live',
			url: streamUrl,
			contentId: chanId,
			sessionId: entitlementState.data.irdetoSession.sessionId,
			ucp_filter: entitlementState.data.ucp_filter,
			cdnAuthToken: cdnAuthState.accessToken,
		}

		const config = getPlayerConfiguration(playerPackage.sessionId, playerPackage.type, playerPackage, configState)
		const url = getStreamUrl(
			playerPackage.type,
			playerPackage.url,
			playerPackage.ucp_filter,
			playerPackage.cdnAuthToken
		)

		return [url, config]
	}, [cdnAuthState?.accessToken, chanId, entitlementState, streamUrl, configState, licenseFailed])

	const error = () => {
		setLicenseFailed(true)
		videoEnd(true)
	}

	if (!trailerUrl || !cdnAuthState?.accessToken || licenseFailed) {
		return null
	}

	return (
		<ShakaPlayer
			url={trailerUrl}
			config={trailerConfig}
			onLoaded={onPlay}
			onError={error}
			ref={shakaPlayer}
			className={styles.billboard_preview_player}
		/>
	)
}

export default PreviewPlayer
